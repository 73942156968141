import { useState, useEffect, useCallback } from 'react';
import { withAuthenticator } from "@aws-amplify/ui-react";
import API, {graphqlOperation} from '@aws-amplify/api-graphql';
import { Alert, Button, ButtonGroup, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { formatCurrency, parseTime, formatTime } from '../services/formatters';
import { getContractPeriod, getContractHourlyRate } from '../services/contract-service';
import { fetchContractByStatus } from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import OverlaySpinner from '../components/overlay-spinner';

function ContractWorkForm(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const onError = props.onError? props.onError: () => {};
    const [isBusy, setIsBusy] = useState(false);
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [createOrUpdate] = useState(location.state && location.state.work ? 'update' : 'create');
    const [contracts, setContracts] = useState([]);
    const [selectedContract, setSelectedContract] = useState(createOrUpdate === 'update' ? location.state.work.contract : {});
    const [validated, setValidated] = useState(false);
    const [formState, setFormState] = useState({ timeWorked: '', notes: '' });//useState(createOrUpdate === 'update' ? location.state.work : { timeWorked: '', notes: '' });
    const [formErrors, setFormErrors] = useState({});
    const [dateRange, setDateRange] = useState({ from: null, to: null });
    const [moneyWorth, setMoneyWorth] = useState(0.0);
    const [daysWorked, setDaysWorked] = useState(undefined);

    const createOrUpdateWork = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsBusy(true);

        const errors = {};

        if (!selectedContract || Object.keys(selectedContract).length === 0) {
            errors.contract = 'Please select a contract';
        }

        if (!formState.timeWorked) {
            errors.timeWorked = 'How many hours did you work?';
        }

        if (dateRange.from == null) {
            errors.dateWorked = 'When did you work?';
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setValidated(false);
            setIsBusy(false);
            return;
        }

        setValidated(true);

        try {
            if (createOrUpdate === 'create') {
                const endDate = dayjs(dateRange.to || dateRange.from);

                let workDay = dayjs(dateRange.from);
                while (workDay.isBefore(endDate) || workDay.isSame(endDate)) {
                    const workDate = workDay.toDate();
                    const work = {
                        ...formState,
                        'contractID': selectedContract.id,
                        'clientID': selectedContract.clientID,
                        'timeWorked': parseTime(formState.timeWorked),
                        'hourlyRate': getContractHourlyRate(selectedContract),
                        'dateWorked': workDate.toLocaleDateString('en-CA'),
                        'fiscalYear': workDate.getFullYear()
                    };

                    await API.graphql(graphqlOperation(mutations.createWork, { input: work }));

                    workDay = workDay.add(1, 'day');
                }
            } else if (createOrUpdate === 'update') {
                const dateWorked = dateRange.from;
                const work = {
                    ...formState,
                    'id': location.state.work.id,
                    'contractID': selectedContract.id,
                    'clientID': selectedContract.clientID,
                    'timeWorked': parseTime(formState.timeWorked),
                    'hourlyRate': getContractHourlyRate(selectedContract),
                    'dateWorked': dayjs(dateRange.from).format('YYYY-MM-DD'),
                    'fiscalYear': dateWorked.getFullYear()
                };
                await API.graphql(graphqlOperation(mutations.updateWork, {input: work}));
            }

            navigate('/home/work');
        } catch (error) {
            setIsBusy(false);
            onError('An unexpected error occured. Please try again.');
            console.error('error adding work', error);
        }
    };

    const searchContracts = async (query) => {
        setIsSearchLoading(true);

        try {
            // const listContractsResp = await API.graphql(graphqlOperation(listContracts));
            const response = await API.graphql(graphqlOperation(fetchContractByStatus, {
                status: 'Approve',
            }));
            const validContracts = response.data.fetchContractByStatus.items.filter((c) => {
                const today= new Date();
                const contractPeriod = getContractPeriod(c);
                const contractStartDate = new Date(contractPeriod.startDate);
                const contractEndDate = c.endDate && new Date(`${contractPeriod.endDate}T23:59:59`);

                console.log('Contract', c, contractPeriod);
                return c.number && c.number.toLowerCase().includes(query.toLowerCase()) &&
                    contractStartDate <= today && (!contractEndDate || contractEndDate >= today);
            });
            setContracts(validContracts);
        } catch (err) {
            console.error('error fetching contracts', err);
        }

        setIsSearchLoading(false);
    };

    const setInput = (key, value) => {
        setFormState({ ...formState, [key]: value });
    };

    const handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, dateRange);
        setDateRange(range);
        formErrors.dateWorked = null;

        let numOfDays = 1;
        if (range.to) {
            numOfDays = dayjs(range.to).diff(dayjs(range.from), 'day') + 1;
        }

        setDaysWorked(numOfDays);
        updateMoneyWorth(selectedContract, numOfDays);
    };

    const updateMoneyWorth = useCallback((contract, numOfDays = 1) => {
        const hoursWorked = parseTime(formState.timeWorked) / 60.0;
        const hourlyRate = getContractHourlyRate(contract);

        let moneyWorth = hoursWorked * hourlyRate * numOfDays;
        moneyWorth = isNaN(moneyWorth) ? 0.0 : moneyWorth;
        setMoneyWorth(moneyWorth);
    }, [formState.timeWorked]);

    useEffect(() => {
        if (location.state && location.state.work) {
            const work = location.state.work;
            setFormState({
                ...formState,
                timeWorked: formatTime(work.timeWorked)
            });

            setDateRange({
                from: dayjs(work.dateWorked).toDate(),
                to: null
            });

            setSelectedContract(work.contract);
            setContracts([work.contract]);
            setDaysWorked(1);

            updateMoneyWorth(work.contract);
        }
    }, [location.state]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <OverlaySpinner show={isBusy}>
            <>
                <Card.Title>Contract Work</Card.Title>
                <Form noValidate validated={validated} onSubmit={createOrUpdateWork}>
                    <Row>
                        <Col sm="6">
                            <Form.Group className="mb-3" controlId="formBasicClient">
                                <Form.Label>Contract</Form.Label>
                                <AsyncTypeahead
                                    required
                                    id="contract-typeahead"
                                    filterBy={() => true}
                                    isLoading={isSearchLoading}
                                    labelKey="number"
                                    minLength={1}
                                    onSearch={searchContracts}
                                    onChange={selected => {
                                        setSelectedContract(selected[0]);
                                        formErrors.contract = null;
                                        updateMoneyWorth(selected[0]);
                                    }}
                                    options={contracts}
                                    // selected={selectedContract? [selectedContract]: []}
                                    placeholder="Start typing ..."
                                    defaultInputValue={selectedContract ? selectedContract.number : ''}
                                    isInvalid={!!formErrors.contract}
                                    renderMenuItemChildren={(contract) => (
                                        <Row>
                                            <div className="d-flex justify-content-between">
                                                <div>{contract.number}</div>
                                                <div>{formatCurrency(getContractHourlyRate(contract), 'USD')}/hour</div>
                                            </div>
                                            <div className="text-center border-bottom">
                                                {contract.client? contract.client.name: "Not Available"}
                                            </div>
                                        </Row>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formBasicWorkDuration">
                                <Form.Label>Time Worked</Form.Label>
                                <Form.Control required type="text" placeholder="e.g. 7h 30m"
                                    onChange={event => {
                                        const value = event.target.value;
                                        setInput('timeWorked', value);
                                    }}
                                    onBlur={(event) => {
                                        updateMoneyWorth(selectedContract);
                                        if (event.target.value === '') {
                                            formErrors.timeWorked = 'How many hours did you work?';
                                        } else {
                                            formErrors.timeWorked = null;
                                        }
                                        formState.timeWorked = formatTime(parseTime(formState.timeWorked));
                                    }}
                                    value={formState.timeWorked}
                                    isInvalid={!!formErrors.timeWorked} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="workForm.Notes">
                                <Form.Label>Notes</Form.Label>
                                <Form.Control as="textarea" rows={3}
                                    value={formState.notes}
                                    onChange={(event => setInput('notes', event.target.value))}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DayPicker
                                selectedDays={[dateRange.from, dateRange]}
                                modifiers={{ start: dateRange.from, end: dateRange.to }}
                                onDayClick={handleDayClick}
                            />
                        </Col>
                        <Col>
                            <Alert variant={formErrors.dateWorked ? "danger" : "info"} className="mt-3">
                                <Alert.Heading>Summary</Alert.Heading>
                                <div className="text-center">
                                    {formErrors.dateWorked}
                                    <p>{dateRange.from && dateRange.from.toLocaleDateString()}</p>
                                    <p>{dateRange.to && ` to `}</p>
                                    <p>{dateRange.to && dateRange.to.toLocaleDateString()}</p>
                                    <hr />
                                    <p>Money Worth: {formatCurrency(moneyWorth)}</p>
                                    <p><small>{getContractHourlyRate(selectedContract) &&
                                        `${formatCurrency(getContractHourlyRate(selectedContract))}/hour`} <FontAwesomeIcon icon={faTimes} size="xs" /> {formState.timeWorked}
                                            { ' '} <FontAwesomeIcon icon={faTimes} size="xs" /> {daysWorked} days
                                    </small></p>
                                </div>
                            </Alert>
                        </Col>
                    </Row>
                    <ButtonGroup className="w-100 mt-3">
                        <Button as={Link} to="/home/work" variant="secondary-outline" type="button">Cancel</Button>
                        <Button variant="primary" type="submit">
                            <span>Save</span>
                        </Button>
                    </ButtonGroup>
                </Form>
            </>
        </OverlaySpinner>
    );
}

export default withAuthenticator(ContractWorkForm);
