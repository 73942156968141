import { useEffect, useState, useCallback, useContext } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import API from '@aws-amplify/api-graphql';
import * as mutations from '../graphql/mutations';
import { faEdit, faSigma, faTrashAlt, faStickyNote } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, Alert, Badge, Button, ButtonGroup, Col, Modal, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatCurrency, formatDate, formatTime } from '../services/formatters';
import { calculateWorkEarnings, fetchWorkForMonth } from '../services/billable-work-service';
import { parseISODateString, startOfMonth, countWorkingDaysMonth } from "../services/calendar-service";
import OverlaySpinner from '../components/overlay-spinner';
import MonthYearSelect from '../components/month-year-select';

import UserContext from '../user-context';

function Work(props) {
    const { darkMode } = useContext(UserContext);

    const [totalIncome, setTotalIncome] = useState(0.0);
    const [selectedMonth, setSelectedMonth] = useState(startOfMonth(new Date()));
    const [countBillableWork, setCountBillableWork] = useState(0.0);
    const [totalTime, setTotalTime] = useState(0);
    const [workingHours, setWorkingHours] = useState(0);
    const [work, setWork] = useState([]);
    const [workByClient, setWorkByClient] = useState({});
    const [incomeByClient, setIncomeByClient] = useState({});
    const [workSummaryByClient, setWorkSummaryByClient] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [activeWorkItem, setActiveWorkItem] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const updateState = useCallback((work) => {
        setWork(work);

        const workSummaryLkp= {};
        let clientIncome = {};
        let clientWork = {};
        let totalTimeWorked = 0;
        let income = 0;
        work.forEach(w => {
            const clientName = w.client.name;
            if (!clientWork[w.client.name]) {
                clientWork[w.client.name] = [];
            }
            clientWork[w.client.name].push(w);

            if (!clientIncome[w.client.name]) {
                clientIncome[w.client.name] = 0;
            }
            clientIncome[w.client.name] += calculateWorkEarnings(w);

            if (!workSummaryLkp[clientName]) {
                workSummaryLkp[clientName] = {countHours: 0};
            }
            (workSummaryLkp[clientName]).countHours += w.timeWorked;

            totalTimeWorked += parseInt(w.timeWorked);
            income += (w.timeWorked / 60) * w.hourlyRate;
        });
        setWorkByClient(clientWork);
        setTotalTime(totalTimeWorked);
        setCountBillableWork(work.length);
        setTotalIncome(income);
        setIncomeByClient(clientIncome);
        setWorkSummaryByClient(workSummaryLkp);
        setWorkingHours(countWorkingDaysMonth(selectedMonth) * 8);
    }, [selectedMonth]);

    const showNotes = (work) => {
        setActiveWorkItem(work);
        setShowNotesModal(true);
    };

    const confirmDelete = (work) => {
        setActiveWorkItem(work);
        setShowDeleteModal(true);
    };

    const cancelDelete = () => {
        setActiveWorkItem({});
        setShowDeleteModal(false);
    };

    const deleteWork = async () => {
        try {
            await API.graphql({
                query: mutations.deleteWork,
                variables: {
                    input: { id: activeWorkItem.id }
                }
            });
            const i = work.indexOf(activeWorkItem);
            if (i > -1) {
                work.splice(i, 1);
            }
            updateState(work);

            setActiveWorkItem({});
            setShowDeleteModal(false);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        let isMounted = true;

        (async () => {
            setIsLoading(true);

            try {
                const work = await fetchWorkForMonth(selectedMonth);
                if (isMounted && work) {
                    updateState(work);
                }
            } catch (error) {
                console.error(error);
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        })();

        return () => {
            isMounted = false;
        };
    }, [updateState, selectedMonth]);

    return (
        <OverlaySpinner show={isLoading}>
            <span className="h1 title text-primary">Billable Work</span>
            <Row>
                <Col className="d-flex justify-content-between">
                    <div></div>
                    <MonthYearSelect onChange={setSelectedMonth}/>
                    <div>
                        <Button variant="primary" as={Link} to="/home/work/new">Add Work</Button>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2 p-4">
                <Col xs="12">
                    <Alert variant="success" className="d-flex d-flex-row justify-content-between mb-0">
                        <div>
                            <FontAwesomeIcon icon={faSigma} /> Income: {formatCurrency(totalIncome)}
                        </div>
                        <div>Billable Work: {countBillableWork}</div>
                        <div>
                            <FontAwesomeIcon icon={faSigma} /> Time: {formatTime(totalTime)}
                        </div>
                        <div>Working Hours: {workingHours}</div>
                    </Alert>
                </Col>
                <Col xs="12">
                    <Accordion defaultActiveKey="0">
                        {Object.keys(workByClient).map((clientName, clientIndex) => {
                            return (
                                <Accordion.Item eventKey={clientIndex} key={clientIndex}>
                                    <Accordion.Header>{clientName}
                                        <Badge bg="success" className="ms-2 p-2">Income: {formatCurrency(incomeByClient[clientName])}</Badge>
                                        <Badge className="ms-2 p-2">Total Time: {workSummaryByClient[clientName]? formatTime(workSummaryByClient[clientName]['countHours']): '0h 0m'}</Badge>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <Table striped bordered hover variant={darkMode? "dark": "light"}>
                                            <thead>
                                                <tr className="text-center">
                                                    <th>Date Worked</th>
                                                    <th>Hourly Rate</th>
                                                    <th>Billable Time</th>
                                                    <th>Earnings</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {workByClient[clientName].sort((l, r) => r.dateWorked > l.dateWorked).map((work, i) => {
                                                    return (
                                                        <tr key={`${clientIndex}-${i}`}>
                                                            <td>{formatDate(parseISODateString(work.dateWorked))}</td>
                                                            <td className="text-end">{formatCurrency(work.hourlyRate)}</td>
                                                            <td className="text-end">{formatTime(work.timeWorked)}</td>
                                                            <td className="text-end">{formatCurrency(calculateWorkEarnings(work))}</td>
                                                            <td className="text-center d-flex justify-content-between">
                                                                <ButtonGroup size="sm">
                                                                    <Button variant="outline-secondary"
                                                                        disabled={!work.notes}
                                                                        onClick={() => showNotes(work)}
                                                                    >
                                                                        <FontAwesomeIcon icon={faStickyNote} />
                                                                    </Button>
                                                                </ButtonGroup>
                                                                <ButtonGroup size="sm">
                                                                    <Button as={Link}
                                                                        to='/home/work/update'
                                                                        state={{ work: work }}
                                                                        variant="outline-primary"
                                                                    >
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                    </Button>
                                                                    <Button variant="outline-danger">
                                                                        <FontAwesomeIcon icon={faTrashAlt} onClick={() => confirmDelete(work)} />
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            );
                        })}
                    </Accordion>
                </Col>
            </Row>
            <Modal
                show={showDeleteModal}
                onHide={deleteWork}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Work</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this work?
                    { activeWorkItem.invoiceID &&
                        <Alert variant="danger" className="mt-3">
                            <strong>Note:</strong> This work is part of an invoice. By deleting, the invoice will be affected.
                        </Alert>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cancelDelete}>
                        No, cancel
                    </Button>
                    <Button variant="danger" onClick={deleteWork}>Yes</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showNotesModal}>
               <Modal.Header closeButton>
                 <Modal.Title>Notes</Modal.Title>
               </Modal.Header>
               <Modal.Body>{activeWorkItem.notes}</Modal.Body>
               <Modal.Footer>
                 <Button variant="primary" onClick={() => setShowNotesModal(false)}>
                   Close
                 </Button>
               </Modal.Footer>
            </Modal>
        </OverlaySpinner>
    );
}

export default withAuthenticator(Work);
