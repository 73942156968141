function isExtensionEffective(extension) {
    return extension.status === 'Approve';
}

export function getContractPeriod(contract) {
    const extension = contract.extension;
    if (extension && isExtensionEffective(extension)) {
        return {
            startDate: extension.startDate,
            endDate: extension.endDate
        }
    }

    return {
        startDate: contract.startDate,
        endDate: contract.endDate
    }
}

export function getContractHourlyRate(contract) {
    const extension = contract.extension;
    if (extension && isExtensionEffective(extension)) {
        return extension.hourlyRate;
    }
    
    return contract.hourlyRate;
}

