import { useState } from 'react';
import { withAuthenticator } from "@aws-amplify/ui-react";
import API, {graphqlOperation} from '@aws-amplify/api-graphql';
import { Form, Button, Card, Container, Row, Col, ButtonGroup, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { createClient, updateClient } from '../graphql/mutations';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const initialClient = { name: '', email: null, phone: null };

function ClientForm(props) {
    const location = useLocation();
    const [createOrUpdate] = useState(location.state && location.state.client ? 'update' : 'create');
    const [formState, setFormState] = useState(createOrUpdate === 'update' ? location.state.client : initialClient);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const createOrUpdateClient = async (event) => {
        event.preventDefault();

        if (!formState.name) {
            setError('Client name is required.');
            return;
        }

        try {
            const client = { ...formState };

            if (formState.phone) {
                client.phone = formState.phone.replace(/-/g, '');
                if (!client.phone.startsWith('+')) {
                    client.phone = '+1' + client.phone;
                }
            }
            if (createOrUpdate === 'create') {
                await API.graphql(graphqlOperation(createClient, { input: client }));
            } else {
                const clone = (({ createdAt, updatedAt, owner, ...o }) => o)(client);
                await API.graphql(graphqlOperation(updateClient, { input: clone }));
            }

            setFormState(initialClient);
            navigate('/home/clients');
        } catch (error) {
            console.log('error creating ', error);
            if (error.errors && error.errors.length > 0) {
                setError(error.errors[0].message);
            }
        }
    };

    function setInput(key, value) {
        setFormState({ ...formState, [key]: value });
    }

    return (
        <Container fluid>
          <Row className="h-100">
            <Col>
                <Card>
                    <Card.Header>{createOrUpdate === 'update' ? 'Update' : 'New'} Client</Card.Header>
                    <Card.Body>
                        <Form onSubmit={createOrUpdateClient}>
                            <Form.Group className="mb-3" controlId="formBasicName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Client name"
                                    onChange={event => setInput('name', event.target.value)}
                                    value={formState.name} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="name@client.com"
                                    onChange={event => setInput('email', event.target.value)}
                                    value={formState.email || ''} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPhone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="phone" placeholder="xxx-xxx-xxxx"
                                    onChange={event => setInput('phone', event.target.value)}
                                    value={formState.phone || ''} />
                                <Form.Text className="text-muted">
                                  Default to US country (+1) code if not provided.
                                </Form.Text>
                            </Form.Group>
                            <ButtonGroup className="w-100">
                                <Button as={Link} to="/home/clients" variant="secondary-outline" type="button">
                                    Cancel
                                </Button>
                                <Button variant="primary" type="submit">
                                    Save
                                </Button>
                            </ButtonGroup>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="d-flex flex-column justify-content-between align-items-end text-light"
        style={{backgroundImage: `url("${process.env.PUBLIC_URL}/images/undraw_interview_re_e5jn.svg")`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition:'center'}}>
              <div className="w-100">
                    {error && <Alert variant="danger">
                        <Alert.Heading>
                            <FontAwesomeIcon icon={faExclamationTriangle}/> Error:
                        </Alert.Heading>
                        <p>
                            {error}
                        </p>
                    </Alert>}
                </div>
            </Col>
        </Row>
        </Container>
    );
}

export default withAuthenticator(ClientForm);
