import React from 'react';

const UserContext = React.createContext({
	  userProfile: {
        company: {},
        theme: {
            dark: true
        }
    },
	setUserProfile: () => {}
});

export default UserContext;
