import { Spinner } from 'react-bootstrap';

function OverlaySpinner(props) {
	return (
		<>
		    {props.show &&
	            <div className="d-flex justify-content-center align-items-center" style={{ "zIndex":10000, position:"absolute", width:"100%", height:"100%", backgroundColor:"aliceblue", top:"0px", left:"0px", opacity:"50%" }}>
	                <Spinner animation="border" />
	            </div>
	        }
	        {props.children}
        </>
    )
}

export default OverlaySpinner;
