import { useState, useEffect } from 'react';
import { withAuthenticator } from "@aws-amplify/ui-react";
import API, {graphqlOperation} from '@aws-amplify/api-graphql';
import { Container, Row, Col, Table, ListGroup } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import * as queries from '../graphql/queries';
import dayjs from 'dayjs';

import { calculateWorkEarnings } from '../services/billable-work-service';
import { formatTime, formatDate, formatCurrency, formatInvoiceNumber } from '../services/formatters';
import { holidaysBetween } from '../services/calendar-service';

function Invoice(props) {
	const location = useLocation();
	const [invoice, setInvoice] = useState({});
	const [invoiceBillableTime, setInvoiceBillableTime] = useState(0);
	const [invoiceAverageRate, setInvoiceAverageRate] = useState(0);
	const [invoiceWork, setInvoiceWork] = useState([]);
	const [federalHolidays, setFederalHolidays] = useState([]);

	useEffect(() => {
		const invoiceId = location.state.invoiceId;
		const getInvoicePromise = API.graphql(graphqlOperation(queries.getInvoice, {id: invoiceId}));

		(async () => {
			try{
				const getInvoiceResponse = await getInvoicePromise;
				const invoice = getInvoiceResponse.data.getInvoice;

				let avgRate = 0;
				let billableTime = 0;
				invoice.work.items.forEach(w => {
					billableTime += w.timeWorked;
					avgRate += w.hourlyRate;
				});
				avgRate /= invoice.work.items.length;

				const holidays = holidaysBetween(dayjs(invoice.startDate).toDate(), dayjs(invoice.endDate).toDate());

				setInvoice(invoice);
				setInvoiceWork(invoice.work.items);
				setInvoiceAverageRate(avgRate);
				setInvoiceBillableTime(billableTime);
				setFederalHolidays(holidays);
			} catch (error) {
				console.error(error);
			}
		})();

		return () => {
			API.cancel(getInvoicePromise);
		};
	}, [location.state.invoiceId]);

	return (
		<Container>
			<h1>INVOICE</h1>
			<Row>
				<Col md="9"></Col>
				<Col md="3">
					<dl className="row">
						<dt className="col-sm-6">Invoice No.</dt>
						<dd className="col-sm-6">{formatInvoiceNumber(invoice.invoiceNumber)}</dd>
						<dt className="col-sm-6">Created</dt>
						<dd className="col-sm-6">{dayjs(invoice.createdAt).format("MMM DD, YYYY")}</dd>
						<dt className="col-sm-6">Due By</dt>
						<dd className="col-sm-6">Upon Receipt</dd>
					</dl>	
				</Col>
			</Row>
			<Row>
				<Col md="4">
					<strong>To:</strong>
					<p>{invoice.client && invoice.client.name}</p>
				</Col>
				<Col md="8">
					<strong>For:</strong>
					<p>
						Services rendered between {dayjs(invoice.startDate).format("MMMM DD, YYYY")} and {" "}
						{dayjs(invoice.endDate).format("MMMM DD, YYYY")}
					</p>
				</Col>
				<hr />
			</Row>
			<Row className="text-center">
				<Col>
					<strong>Billable Time:</strong> <br /> {formatTime(invoiceBillableTime)}
				</Col>
				<Col>
					<strong>Average Rate:</strong> <br /> {formatCurrency(invoiceAverageRate)}
				</Col>
				<Col>
					<strong>Invoice Amount:</strong> <br /> {formatCurrency(invoice.invoiceAmount)}
				</Col>
			</Row>
			<Row className="mt-5">
				<div><strong>Federal holidays</strong> (Total: {federalHolidays.length})</div>
				<ListGroup variant="flush">
					{federalHolidays.map(holiday =>
						<ListGroup.Item key={holiday.name}>
							<strong>{holiday.alsoObservedAs || holiday.name}</strong>{' - '}
							{formatDate(new Date(holiday.date))}
						</ListGroup.Item>
					)}
				</ListGroup>
			</Row>
			<h3 className="text-center mt-3">Billable Time</h3>
			<Table responsive>
				<thead>
					<tr>
						<th>Date</th>
						<th className="text-end">Rate ($) / Hour</th>
						<th className="text-end">Time</th>
						<th className="text-end">Amount ($)</th>
						<th>Notes</th>
					</tr>
				</thead>
				<tbody>
					{invoiceWork.sort((a,b) => dayjs(a.dateWorked).isBefore(dayjs(b.dateWorked))).map(w =>
						<tr key={w.id}>
							<td>{formatDate(dayjs(w.dateWorked).toDate())}</td>
							<td className="text-end">{formatCurrency(w.hourlyRate)}</td>
							<td className="text-end">{formatTime(w.timeWorked)}</td>
							<td className="text-end">{formatCurrency(calculateWorkEarnings(w))}</td>
							<td>{w.notes}</td>
						</tr>
					)}
				</tbody>
			</Table>
		</Container>
	);
}

export default withAuthenticator(Invoice);
