import { useEffect, useState, useCallback } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import API, { graphqlOperation } from '@aws-amplify/api-graphql';
import Auth from '@aws-amplify/auth';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChartBar,
    faSync,
    faTable,
    faFileContract,
    faMoneyBill
} from "@fortawesome/pro-light-svg-icons";
import { Col, Row, Card, Button, Alert, Table, ProgressBar } from "react-bootstrap";
import { countWorkingDaysMonth, countWorkingDaysYear } from "../services/calendar-service";
import { formatCurrency, formatDate } from "../services/formatters";

import { listWorkSummaries, listClients, listContracts } from "../graphql/queries";

import {
    ComposedChart,
    Bar,
    Line,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    LabelList,
} from "recharts";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import OverlaySpinner from '../components/overlay-spinner';

dayjs.extend(customParseFormat);

function randomColor() {
    return "hsl(" + 360 * Math.random() + ',' +
         (25 + 70 * Math.random()) + '%,' + 
         (85 + 10 * Math.random()) + '%)'
}

const barColors = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(255, 159, 64, 0.2)",
    "rgba(255, 205, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(201, 203, 207, 0.2)",
    "rgba(99, 99, 255, 0.2)",
    "rgba(64, 159, 255, 0.2)",
    "rgba(86, 205, 255, 0.2)",
    "rgba(235, 162, 54, 0.2)",
    "rgba(255, 102, 153, 0.2)"
];

function Dashboard(props) {
    const [state, setState] = useState({
        maxWorkHours: 0,
        projectedIncome: {},
        workSummaryByMonth: {},
        clients: new Set(),
        averageRate: 0,
        timeWorkedPercent: 0,
        activeContracts: []
    });
    const [currentMonth] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [lastRefreshTime, setLastRefreshTime] = useState(false);

    const reload = () => {
        setLastRefreshTime(Date.now());
    };

    // const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    //     console.log(payload);
    //     return (
    //         <text
    //             x={x + width / 2}
    //             y={y}
    //             fill="#333"
    //             textAnchor="middle"
    //             dy={-6}
    //             fontSize={12}
    //             fontFamily="sans-serif"
    //         >
    //             {`${formatCurrency(value * 100)}`}
    //         </text>
    //     );
    // };

    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={5}
                    dy={0}
                    textAnchor="end"
                    fill="#667"
                    transform="rotate(-25)"
                >
                    {payload.value}
                </text>
            </g>
        );
    };

    const AxisLabel = ({ axisType, x, y, width, height, stroke, children }) => {
        const isVert = axisType === "yAxis";
        const cx = isVert ? x : x + width / 2;
        const cy = isVert ? height / 2 + y : y + height + 10;
        const rot = isVert ? `270 ${cx} ${cy}` : 0;

        return (
            <text
                x={cx}
                y={cy}
                transform={`rotate(${rot})`}
                textAnchor="middle"
                stroke={stroke}
            >
                {children}
            </text>
        );
    };

    const WorkDetailTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            let clientIncomeLkp = {}
            if (payload.length > 0) {
                const firstPayload = payload[0];
                const clientIncome = firstPayload.payload.incomeByClient;
                if (clientIncome) {
                    // clientIncome.forEach((income, client, map) => 
                    for (const [clientId, income] of Object.entries(clientIncome)) {
                        const client = state.clients[clientId]
                        clientIncomeLkp[client.name] = {
                            legend: state.clientColors[clientId],
                            income: formatCurrency(income),
                        };
                    }
                }
            }

            return (
                <Alert
                    variant="light"
                    className="d-flex flex-column align-items-center"
                    style={{ opacity: "0.9" }}
                >
                    <Alert.Heading>{`${label}`}</Alert.Heading>
                    {/* <p>{formatCurrency(payload[0].value * 100)}</p> */}
                    <table className="table table-sm table-borderless align-middle" width="100%">
                        <tbody>
                            { Object.keys(clientIncomeLkp).map(client =>
                                <tr key={`i-${client}`}>
                                    <td>
                                        <div className="p-2 border border-dark" style={{backgroundColor: clientIncomeLkp[client].legend}}></div>
                                    </td>
                                    <td>{client}</td>
                                    <td className="text-start">{clientIncomeLkp[client].income}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Alert>
            );
        }

        return <></>;
    };

    const initializeState = useCallback((listClientsResponse, listWorkSummariesResponses, listContractsResponse) => {
        const clientsLookup = listClientsResponse.data.listClients.items.reduce((map, c) => {
            map[c.id] = c;
            return map;
        }, {});

        const activeContracts = listContractsResponse.data.listContracts.items
            .filter(contract => contract.status === 'Approve');

        const workSummary = listWorkSummariesResponses
            .map((e) => e.data.listWorkSummaries.items)
            .reduce((all, resp) => all.concat(resp), []);

        const workSummaryByMonthMap = convertWorkSummaryArrayToMonthlyMap(workSummary);
        const workBarChartData = Object.values(workSummaryByMonthMap);
        const monthYear = dayjs().format("MMMM YYYY");
        const workSummaryForCurrentMonth = workSummaryByMonthMap[monthYear] || {};
        const totalTimeWorked = workSummaryForCurrentMonth.totalTimeWorked;
        const maxWorkHoursMonth = countWorkingDaysMonth(currentMonth) * 8;
        const maxWorkHoursYear = countWorkingDaysYear(currentMonth) * 8;
        const avgHourlyRate = calculateAverageHourlyRate(workSummary);
        const projectedIncomeMonth = maxWorkHoursMonth * avgHourlyRate;
        const projectedIncomeYear = maxWorkHoursYear * avgHourlyRate;
        const workSummaryByClient = [];
        const incomeByClient = workSummaryForCurrentMonth['incomeByClient'] || {};
        for (const [clientId, income] of Object.entries(incomeByClient)) {
            workSummaryByClient.push({
                'client': clientsLookup[clientId].name,
                'income': income
            });
        }

        const clientColors = {};
        Object.keys(clientsLookup).forEach(clientId => {
            clientColors[clientId] = randomColor()
        });
        /*
         * "hsl(77.85434636560652,58.03623085872073%,85.44799538808105%)"
​
"92819ecb-b3b3-4c80-846d-d6ab39a22cd7": "hsl(60.47138235731209,71.30174730165592%,89.62733949692873%)"
         * */
        // console.log('clientColors', clientColors);

        return {
            maxWorkHours: maxWorkHoursMonth,
            workBarChartData: workBarChartData,
            clients: clientsLookup,
            workSummaryByMonth: workSummaryByMonthMap,
            workSummaryByClient: workSummaryByClient,
            projectedIncomeMonth: projectedIncomeMonth,
            projectedIncomeYear: projectedIncomeYear,
            averageHourlyRate: avgHourlyRate,
            totalTimeWorkedMonth: totalTimeWorked,
            timeWorkedPercent: (totalTimeWorked / (maxWorkHoursMonth * 60)) * 100.0,
            activeContracts: activeContracts,
            clientColors: clientColors,
        };
    }, [currentMonth]);

    const convertWorkSummaryArrayToMonthlyMap = (workSummary) => {
        const today = dayjs();
        const twelveMonthsAgo = dayjs().add(-12, "month").startOf("month");
        const workSummaryByMonthMap = {};

        for (let m = twelveMonthsAgo; m.isBefore(today); m = m.add(1, 'month')) {
            workSummaryByMonthMap[m.format("MMMM YYYY")] = {
                month: m.format("MMMM YYYY"),
                total: 0.0,
            };
        }

        for (const summary of workSummary) {
            const month = dayjs(`${summary.year}-${summary.month}`);
            const monthYear = month.format("MMMM YYYY");
            const incomeByClient = JSON.parse(summary.incomeByClient);
            const monthlySummary = {
                month: monthYear,
                total: summary.totalIncome,
                totalTimeWorked: summary.totalTimeWorked,
                workCount: summary.workCount,
                sumHourlyRate: summary.sumHourlyRate,
                incomeByClient: { ...incomeByClient },
            };
            workSummaryByMonthMap[monthYear] = monthlySummary;
        }

        return workSummaryByMonthMap;
    };

    const calculateAverageHourlyRate = (workSummary) => {
        const count = workSummary.filter(w => w['sumHourlyRate']).length;
        const avgHourlyRate = workSummary
            .map(w => w['sumHourlyRate'] / w['workCount'])
            .reduce((t, r) => t = t + r, 0)
            / count;

        return avgHourlyRate;
    };

    useEffect(() => {
        const currentUserPromise = Auth.currentAuthenticatedUser();
        const listWorkSummariesPromises = [];
        let listClientsPromise = undefined;
        let listContractsPromise = undefined;

        let isActiveSelf = true;

        (async () => {
            try {
                setIsLoading(true);
                const today = dayjs();
                const twelveMonthsAgo = dayjs().add(-12, "month").startOf("month");
                const currentUserResponse = await currentUserPromise;
                const currentAuthenticatedUserId = currentUserResponse.username;

                listClientsPromise = API.graphql(graphqlOperation(listClients));
                listContractsPromise = API.graphql(graphqlOperation(listContracts));

                const years = [today.year()];
                if (twelveMonthsAgo.year() !== today.year()) {
                    years.push(twelveMonthsAgo.year());
                }
                for (const year of years) {
                    listWorkSummariesPromises.push(
                        API.graphql(
                            graphqlOperation(listWorkSummaries, {
                                id: `${currentAuthenticatedUserId}:${year}`,
                                yearMonth: {
                                    between: [
                                        parseInt(`${twelveMonthsAgo.format("YYYYMM")}`),
                                        parseInt(`${today.format("YYYYMM")}`),
                                    ],
                                },
                            })
                        )
                    );
                }

                const listClientsResponse = await listClientsPromise;
                const listWorkSummariesResponses = await Promise.all(listWorkSummariesPromises);
                const listContractsRespose = await listContractsPromise;

                if (isActiveSelf) {
                    const newState = initializeState(listClientsResponse, listWorkSummariesResponses, listContractsRespose);
                    setState(newState);
                }
            } catch (error) {
                if (API.isCancel(error)) {
                    console.error('Cancel work promise error', error.message);
                    // handle user cancellation logic
                }
                console.error(error);
            } finally {
                if (isActiveSelf) {
                    setIsLoading(false);
                }
            }
        })();

        return () => {
            isActiveSelf = false;

            if (listClientsPromise) {
                API.cancel(listClientsPromise, '');
            }
            for (const promise of listWorkSummariesPromises) {
                API.cancel(promise, 'Unable to cancel promise');
            }
        };
    }, [lastRefreshTime, initializeState]);

    const getTimeComponents = (time) => {
        const hours = parseInt(time / 60);
        const minutes = parseInt(time % 60);

        return { hours, minutes };
    };

    const DollarsCurrentMonth = () => {
        const monthYear = dayjs().format("MMMM YYYY");
        const workSummary = state.workSummaryByMonth[monthYear] || {};

        return <>{formatCurrency(workSummary["total"])}</>;
    };

    const TimeWorkedForCurrentMonth = (props) => {
        const monthYear = dayjs().format("MMMM YYYY");
        const workSummary = state.workSummaryByMonth[monthYear] || {};
        const totalTimeWorked = workSummary.totalTimeWorked;
        const timeComponents = getTimeComponents(totalTimeWorked);

        return (
            <>
                <span className={props.small ? "h6" : "h3"}>
                    {isNaN(timeComponents.hours) ? 0 : timeComponents.hours}
                </span>{" "}
                <span className="text-muted"> hrs</span>
                {",  "}
                <span className={props.small ? "h6" : "h3"}>
                    {isNaN(timeComponents.minutes) ? 0 : timeComponents.minutes}
                </span>{" "}
                <span className="text-muted"> mins</span>
            </>
        );
    };

    const CountBillableWork = () => {
        const monthYear = dayjs().format("MMMM YYYY");
        const workSummary = state.workSummaryByMonth[monthYear] || {};
        const workCount = workSummary["workCount"] || 0;

        return (<>{workCount}</>);
    };

    const DollarsYearToDate = () => {
        const currentYear = dayjs().year();

        const totalCents = Object.keys(state.workSummaryByMonth)
            .filter(
                (monthYear) =>
                    dayjs(monthYear, "MMMM YYYY").year() === currentYear
            )
            .reduce(
                (totalCents, monthYear) =>
                    totalCents + state.workSummaryByMonth[monthYear]["total"],
                0
            );

        return (<>{formatCurrency(totalCents)}</>);
    };

    const MostValuableClient = () => {
        if (!state.workSummaryByClient) { return <></>; }

        const mostValuableClient = state.workSummaryByClient.reduce((m, client) => {
            return client.income > m.income ? client : m;
        }, { client: 'Not Available', 'income': -1 });

        return (
            <>
                <div className="h3 text-center">
                    {mostValuableClient.client}
                </div>
                {mostValuableClient.income > -1 &&
                    <small className="ps-2">Total Income: {formatCurrency(mostValuableClient.income)}</small>
                }
            </>
        );
    };
    const ClientIncomeTable = () => {
        if (!state.workSummaryByClient) {
            return <></>;
        }
        const monthYear = dayjs().format("MMMM YYYY");
        const workSummary = state.workSummaryByMonth[monthYear] || {};
        const totalDollarsForMonth = workSummary["total"];

        return (
            <Table>
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>Income</th>
                    </tr>
                </thead>
                <tbody>
                    {state.workSummaryByClient.sort((a, b) => a.income < b.income)
                        .map(clientSummary => {
                            return (
                                <tr key={clientSummary.client}>
                                    <td>{clientSummary.client}</td>
                                    <td>
                                        {formatCurrency(clientSummary.income)}{' '}
                                        ({((clientSummary.income / totalDollarsForMonth) * 100).toFixed(0)}%)
                                    </td>
                                </tr>
                            );
                        }
                        )}
                </tbody>
            </Table>
        );
    };

    const ActiveContractsTable = () => {
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Contract</th>
                        <th>Hourly Rate</th>
                    </tr>
                </thead>
                <tbody>
                    {state.activeContracts.map(contract => {
                        return (
                            <tr key={contract.id}>
                                <td>
                                    {contract['number']}
                                </td>
                                <td>
                                    {formatCurrency(contract['hourlyRate'])}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    }

    return (
        <OverlaySpinner show={isLoading}>
            <Row className="mb-4">
                <Col
                    sm="12"
                    md="2"
                    lg="2"
                    className="d-flex flex-column justify-content-start align-items-start"
                >
                    <div className="h1 title text-primary">Dashboard</div>
                    <Button
                        variant="outline-info"
                        size="sm"
                        onClick={() => reload()}
                    >
                        {isLoading ? (
                            <FontAwesomeIcon icon={faSync} spin fixedWidth />
                        ) : (
                            <FontAwesomeIcon icon={faSync} fixedWidth />
                        )}
                    </Button>
                </Col>

                <Col className="d-flex flex-column justify-content-between ms-5 px-3 py-1 border border-info">
                    <div className="d-flex flex-row justify-content-between">
                        <div className="text-center text-muted">
                            &micro; Hourly Rate
                            <div className="text-primary">
                                <h6>{formatCurrency(state.averageHourlyRate)}</h6>
                            </div>
                        </div>

                        <div className="text-center text-muted">
                            &sum; Work
                            <div className="text-primary">
                                <CountBillableWork />
                            </div>
                        </div>

                        <div className="text-center text-muted">
                            <span tooltip="Based on 8 work hours per day, excluding federal holidays">
                                Work hours
                            </span>
                            <div className="text-primary">{state.maxWorkHours}</div>
                        </div>
                        <div className="text-center text-muted">
                            Today is{" "}
                            <div className="text-primary">
                                {formatDate(currentMonth)}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                        <div className="text-center text-muted">
                            Number of Federal Holidays
                            <div className="text-primary">
                                1
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xl="3" sm="6" className="mb-3">
                    <Card bg="secondary" border="dark" style={{ height: "140px" }}>
                        <Card.Body>
                            <Card.Title>Hours Worked</Card.Title>
                            <Card.Text className="text-center">
                                <TimeWorkedForCurrentMonth />
                            </Card.Text>
                            <ProgressBar variant="success" now={state.timeWorkedPercent} label={`${state.timeWorkedPercent.toFixed(2)}%`} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl="3" sm="6" className="mb-3">
                    <Card
                        bg="primary" border="dark"
                        className="text-white"
                        style={{ height: "140px" }}
                    >
                        <Card.Body>
                            <Card.Title>Dollars for the month</Card.Title>
                            {/*<h3>{formatCurrency(state.totalIncome)}</h3>*/}
                            <h3>
                                <DollarsCurrentMonth />
                            </h3>
                            <small className="text-white ms-3">Projected: {formatCurrency(state.projectedIncomeMonth)}</small>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl="3" sm="6" className="mb-3">
                    <Card bg="secondary" border="dark" style={{ height: "140px" }}>
                        <Card.Body>
                            <Card.Title>Year-to-date</Card.Title>
                            <div>
                                <h3>
                                    <DollarsYearToDate />
                                </h3>
                                <small className="text-muted ms-3">Projected: {formatCurrency(state.projectedIncomeYear)}</small>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl="3" sm="6" className="mb-3">
                    <Card bg="success" border="dark" text="white"
                        style={{ height: "140px" }}
                    >
                        <Card.Body>
                            <Card.Title>Most Valuable Client</Card.Title>
                            <MostValuableClient />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <Card>
                        <Card.Header>
                            <FontAwesomeIcon icon={faChartBar} /> Total monthly income
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
                                <ComposedChart
                                    width={500}
                                    height={300}
                                    data={state.workBarChartData}
                                    margin={{
                                        top: 50,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="month"
                                        height={80}
                                        interval={0}
                                        tick={CustomizedAxisTick}
                                    />
                                    <YAxis
                                        label={
                                            <AxisLabel
                                                axisType="yAxis"
                                                x={25}
                                                y={225}
                                                width={0}
                                                height={0}
                                            >
                                                Monthly Earnings ($)
                                            </AxisLabel>
                                        }
                                    />
                                    <Tooltip content={<WorkDetailTooltip />} />
                                    {Object.keys(state.clients).map((client, idx) =>
                                        <Bar key={`bar-${client.id}`}
                                            dataKey={(data) => {
                                                let value = 0;
                                                if (data.incomeByClient) {
                                                    const clientIncome = data.incomeByClient[client];
                                                    if (clientIncome){
                                                        value = clientIncome;
                                                    }
                                                }

                                                return (value / 100).toFixed(2)
                                            }}
                                            stackId="1"
                                            // fill="#ffffff"
                                            fill={state.clientColors[client]}
                                            stroke="#000000"
                                            fillRule="evenodd"
                                        >
                                            { state.clients && idx === Object.keys(state.clients).length - 1 ?
                                                <LabelList
                                                    position="top"
                                                    valueAccessor={entry => {
                                                        // console.log('Entry', entry);
                                                        return formatCurrency(entry.payload.total);
                                                    }}
                                                />
                                                : null
                                            }
                                        </Bar>
                                    )}
                                    {Object.keys(state.clients).map((client, idx) =>
                                        <Line key={`line-${client.id}`}
                                            dataKey={(data) =>
                                                (data.total / 100).toFixed(2)
                                            }
                                            stroke="#000000"
                                        >
                                        </Line>
                                    )}
                                    {/*                            {Array.from(state.clients).map((client, index) => (
                                        <Bar
                                            key={index}
                                            dataKey={`${client}`}
                                            stackId="1"
                                            fill={barColors[index % 12]}
                                            fillRule="evenodd"
                                        >
                                            {index === state.clients.size - 1 ? (
                                                <LabelList
                                                    position="top"
                                                    content={renderCustomBarLabel}
                                                />
                                            ) : null}
                                        </Bar>
                                    ))}
        */}{" "}
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Header>
                            <FontAwesomeIcon icon={faTable} /> Monthly Income By Client
                        </Card.Header>
                        <Card.Body>
                            <ClientIncomeTable />
                        </Card.Body>
                    </Card>
                    {/*<br />
                    <Card>
                        <Card.Header>
                            <FontAwesomeIcon icon={faMoneyBill} /> High Value Months
                        </Card.Header>
                        <Card.Body>
                        </Card.Body>
                    </Card>*/}
                    <br />
                    <Card>
                        <Card.Header>
                            <FontAwesomeIcon icon={faFileContract} /> Active Contracts
                        </Card.Header>
                        <Card.Body>
                            <ActiveContractsTable />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </OverlaySpinner>
    );
}

export default withAuthenticator(Dashboard);
