import { useState, useEffect } from "react";
import API, {graphqlOperation} from '@aws-amplify/api-graphql';
import * as queries  from "../graphql/queries";
import { Card, Col, Container, Row, Form, ButtonGroup, Button, Alert } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import * as mutations from "../graphql/mutations";
import dayjs from 'dayjs';
import "react-day-picker/lib/style.css";

import OverlaySpinner from "./overlay-spinner";
import { fetchWorkBetween, calculateWorkEarnings } from '../services/billable-work-service';

function InvoiceForm(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [clients, setClients] = useState([]);
    const [formState, setFormState] = useState({
        client: '',
        startDate: '',
        endDate: '',
    });
    const [formError, setFormError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [validated] = useState(false);
    const [createOrUpdate] = useState(
        location.state && location.state.invoice ? "update" : "create"
    );

    function validateForm(formState) {
        const errors = {};
        if (!formState.client) {
            errors['client'] = 'Please choose a client.';
        }
        if (!formState.startDate) {
            errors['startDate'] = 'Please choose a start date';
        }
        if (!formState.endDate) {
            errors['endDate'] = 'Please choose an end date';
        }

        return errors;
    }

    function setInput(key, value, ...rest) {
        if (rest.length % 2 !== 0) {
            throw new Error('Improper form value format');
        }

        const newFormState = { ...formState, [key]: value };
        for (let i=0; i<rest.length; i+=2) {
            newFormState[rest[i]] = rest[i+1];
        }

        setFormState(newFormState);
        setFormError(validateForm(newFormState));
    }

    useEffect(() => {
        let isActive = true;
        const clientsPromise = API.graphql(
            graphqlOperation(queries.listClients)
        );

        (async () => {
            try {
                const clientsData = await clientsPromise;
                if (isActive) {
                    const clients = clientsData.data.listClients.items;
                    setClients(clients);
                }
            } catch (err) {
                console.error("error fetching clients", err);
            }
        })();

        return () => {
            API.cancel(clientsPromise);
            isActive = false;
        };
    }, []);

    const setCurrentMonth = () => {
        const today = dayjs();
        const startOfMonth = today.startOf('month').format('YYYY-MM-DD');
        const endOfMonth = today.endOf('month').format('YYYY-MM-DD');

        setInput('startDate', startOfMonth, 'endDate', endOfMonth);
    };

    const saveInvoice = async (event) => {
        setIsLoading(true);
        event.preventDefault();

        const formErrors = validateForm(formState);
        if (Object.keys(formErrors).length > 0) {
            setIsLoading(false);
            setFormError(formErrors);
            return;
        }

        try {
            const clientID = formState.client;
            const workPromises = fetchWorkBetween(dayjs(formState.startDate).toDate(), dayjs(formState.endDate).toDate());
            const workResponses = await Promise.all(workPromises);
            const work = workResponses
                .map(e => e.data.fetchWorkByFiscalYear.items)
                .reduce((all, resp) => all.concat(resp), [])
                .filter(e => e.clientID === clientID);
            const invoiceAmount = work.reduce((amt, w) => amt += calculateWorkEarnings(w), 0.0);

            const invoice = {
                invoiceNumber: '-1',
                clientID: clientID,
                startDate: formState.startDate,
                endDate: formState.endDate,
                invoiceAmount: invoiceAmount,
                isPaid: false
            };

            const newInvoiceResponse = await API.graphql(graphqlOperation(mutations.createInvoice, { input: invoice }));
            const newInvoiceId = newInvoiceResponse.data.createInvoice.id;

            work.forEach( async w => {
                await API.graphql(graphqlOperation(mutations.updateWork, {input: {
                    id: w.id,
                    invoiceID: newInvoiceId
                }}));
            });
            navigate('/home/invoices');
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container fluid className="h-100">
            <OverlaySpinner show={isLoading}>
                <Row className="h-100">
                    <Col>
                        <Card style={{ width: "40rem" }}>
                            <Card.Header>
                                {createOrUpdate === "update" ? "Update" : "New"}{" "}
                                Invoice
                            </Card.Header>
                            <Card.Body>
                                <Form
                                    noValidate
                                    validated={validated}
                                    onSubmit={saveInvoice}
                                >
                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Client</Form.Label>
                                                <Form.Select aria-label="Select client for invoice" value={formState.client}
                                                             onChange={(event) => setInput('client', event.target.value)}
                                                >
                                                  <option disabled value={""} key={-1}>
                                                    Select a client
                                                  </option>
                                                  {clients.map(c =>
                                                      <option value={c.id} key={c.id}>{c.name}</option>
                                                  )};
                                                </Form.Select>
                                                {/*<ClientTypeahead
                                                    onChange={(selected) => {
                                                            setFormState({
                                                                ...formState,
                                                                client: selected
                                                            });
                                                            if (selected) {
                                                                setFormError({
                                                                    ...formError,
                                                                    client: null
                                                                });
                                                            }
                                                        }
                                                    }
                                                    required
                                                    />*/}
                                                <Form.Control.Feedback type="invalid">{formError['client']}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicStartDate">
                                                <Form.Label className="mb-3">Start date</Form.Label>
                                                <Form.Control type="date" placeholder="Effective date" tabIndex="2"
                                                    onChange={event => setInput('startDate', event.target.value)}
                                                    value={formState.startDate} />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEndDate">
                                                <Form.Label className="w-100">
                                                    <div className="d-flex justify-content-between">
                                                        End date {" "}
                                                        <Button variant="outline-secondary" size="sm" tabIndex="4" onClick={setCurrentMonth}>Current month</Button>  
                                                    </div>
                                                </Form.Label>
                                                <Form.Control type="date" placeholder="Expiration date" tabIndex="3"
                                                    onChange={event => setInput('endDate', event.target.value)}
                                                    value={formState.endDate} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <ButtonGroup className="w-100">
                                        <Button as={Link} to="/home/invoices" variant="secondary-outline" type="button">
                                            Cancel
                                        </Button>
                                        <Button variant="primary" type="submit">Save</Button>
                                    </ButtonGroup>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                  <Col
                    style={{backgroundImage: `url("${process.env.PUBLIC_URL}/images/undraw_printing_invoices_-5-r4r.svg")`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}
                  >
                      {Object.keys(formError).length > 0 &&
                        <Alert variant="danger">
                            <Alert.Heading>Please fix the below errors</Alert.Heading>
                            <ul>
                              {Object.keys(formError).map( error => <li key={error}>{formError[error]}</li>)}
                            </ul>
                        </Alert>
                      }
                    </Col>
                </Row>
            </OverlaySpinner>
        </Container>
    );
}

export default InvoiceForm;
