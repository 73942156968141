import { useState } from 'react';
import API, { graphqlOperation } from '@aws-amplify/api-graphql';
import * as mutations from "../graphql/mutations";
import { Alert, Button, Card, ButtonGroup, Dropdown, DropdownButton, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatCurrency } from "../services/formatters";
import { getContractHourlyRate, getContractPeriod } from '../services/contract-service';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBuilding,
    faClock,
    faEdit,
    faMoneyBillWave,
    faTrash,
    faCalendarPlus
} from "@fortawesome/pro-light-svg-icons";
import { withAuthenticator } from '@aws-amplify/ui-react';
import { HourlyRateDelta } from './HourlyRateDelta';

function ContractCard(props) {
    const contract = props.contract;
    const contractPeriod = getContractPeriod(contract);
    const idx = props.idx;
    const statusChangeHandler = props.onStatusChange;
    const deleteHandler = props.onDelete;
    const [error, setError] = useState('');

    const statusBadgeColor = (contract) => {
        if (contract.status === 'Expired') {
            return 'danger';
        }

        return 'info';
    };

    const deleteContractHandler = async (contractId) => {
        try {
            await API.graphql(
                graphqlOperation(mutations.deleteContract, { input: { id: contractId } })
            );
            deleteHandler && deleteHandler();
        } catch (e) {
            console.error(`ERROR:deleteContractHandler:contractId=${contractId}`, e);
            setError('Error deleting contract. Please try again');
        }
    };

    const changeContractStatus = async (contract, status) => {
        await API.graphql(graphqlOperation(mutations.updateContract, {
            input: {
                id: contract.id,
                status: status
            }
        }));

        statusChangeHandler && statusChangeHandler(status);
    };

    return (
        <Card key={idx}
            border={contract.status && contract.status.toLowerCase() === 'approve'? "success" : "secondary"}
            style={{ "maxWidth": "30rem" }}
        >
            <Card.Header as="h5"
                className={`${contract.status && contract.status.toLowerCase() === 'approve' ? "bg-success" : "bg-light"} ${contract.status && contract.status.toLowerCase() === 'approve' ? "text-white" : "text-dark"}`}
                bg={contract.status && contract.status.toLowerCase() === 'approve' ? "success" : "light"}
                text={contract.status && contract.status.toLowerCase() === 'approve' ? "white" : "dark"}
            >
                {contract.number}
                {contract.extensionID && 
                    <Badge bg={statusBadgeColor(contract)} className="float-end">Extended</Badge>}
            </Card.Header>
            <Card.Body>
                <Card.Subtitle>
                    <Badge bg={statusBadgeColor(contract)} className="float-end">{contract.status}</Badge>
                </Card.Subtitle>
                <dl>
                    <dt>
                        <FontAwesomeIcon
                            icon={faBuilding}
                        />{" "}
                        Client
                    </dt>
                    <dl className="ms-3">
                        {contract.client
                            ? contract.client.name
                            : "Not Available"}
                    </dl>
                    <dt>
                        <FontAwesomeIcon icon={faClock} />{" "}
                        Effective
                    </dt>
                    <dl className="ms-3">
                        <span className="text-muted">
                            From{" "}
                        </span>
                        {contractPeriod.startDate} <br />
                        <span className="text-muted">
                            {" "}
                            To{" "}
                        </span>
                        <span className="ms-3">
                            {contractPeriod.endDate}
                        </span>
                    </dl>
                    <dt>
                        <FontAwesomeIcon
                            icon={faMoneyBillWave}
                            className="text-success"
                        />{" "}
                        Hourly Rate
                    </dt>
                    <dl className="ms-3">
                        { formatCurrency(getContractHourlyRate(contract)) }
                        &nbsp;
                        { contract.extension && <span>(<HourlyRateDelta extension={contract.extension} />)</span> }
                    </dl>
                </dl>
            </Card.Body>
            {error && <Alert variant="danger" className="mb-0 rounded-0" onClose={() => setError('')} dismissible>{error}</Alert>}
            <Card.Footer className="d-flex justify-content-between bg-light text-dark">
                <ButtonGroup>
                    {(contract.status === 'New' || contract.status === 'Draft') &&
                        <>
                            <Button
                                as={Link}
                                to="/home/contract/update"
                                state={{ contract: contract }}
                                variant="outline-primary"
                                type="button"
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                            <Button
                                onClick={() => {
                                    deleteContractHandler(
                                        contract.id
                                    );
                                }}
                                variant="outline-danger"
                                type="button"
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </>
                    }
                    {(contract.status === 'Approve' || contract.status === 'Expire') &&
                        <>
                            <Button
                                as={Link}
                                to="/home/contract/update"
                                state={{ contract: contract }}
                                variant="outline-primary"
                                type="button"
                            >
                                <FontAwesomeIcon icon={faCalendarPlus} />
                            </Button>
                        </>
                    }
                </ButtonGroup>
                <DropdownButton variant="outline-primary" title="Change Status">
                    <Dropdown.Item onClick={() => changeContractStatus(contract, 'New')}>New</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeContractStatus(contract, 'WaitingStart')}>Waiting Start</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeContractStatus(contract, 'Draft')}>Draft</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeContractStatus(contract, 'Approve')}>Approve</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeContractStatus(contract, 'Close')}>Close</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeContractStatus(contract, 'Cancel')}>Cancel</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeContractStatus(contract, 'Expired')}>Expire</Dropdown.Item>
                </DropdownButton>
            </Card.Footer>
        </Card>)
}

export default withAuthenticator(ContractCard);

