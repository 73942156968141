import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faUserCircle, faMoon, faCog, faSunBright } from '@fortawesome/pro-light-svg-icons';
import { Nav, Navbar, NavDropdown, Container, } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Link } from "react-router-dom";
import ActionBar from './actionbar';

import UserContext from '../user-context';
// let loading = false;
// let userSignedOut = false;

function Navigator(props) {
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const { userProfile, darkMode, updateDarkMode } = useContext(UserContext);

    function GreetOrSignIn() {
        const greeting = userProfile.company && userProfile.company.name
            ? userProfile.company.name
            : userProfile.firstName;

        if (user !== undefined) {
            return (
                <UserContext.Consumer>
                    {context => (
                        <>
                            <ActionBar horizontal />
                            {/*flex-grow-0 */}
                            <Navbar.Collapse className="w-25 flex-grow-0 justify-content-evenly">
                                <Navbar.Text>
                                    {greeting}
                                </Navbar.Text>
                                
                                <NavDropdown align="end" menuVariant={darkMode ? "dark" : "light"}
                                    title={<FontAwesomeIcon icon={faCog} size="lg" className={darkMode ? "text-light" : "text-dark"} />}
                                    id="nav-settings-dropdown"
                                >
                                    <NavDropdown.ItemText>
                                        <div className="d-flex justify-content-between">
                                            Appearance
                                            {darkMode
                                                ? <FontAwesomeIcon icon={faMoon} size="lg" />
                                                : <FontAwesomeIcon icon={faSunBright}  size="lg" />
                                            }
                                        </div>
                                    </NavDropdown.ItemText>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Container} to="/home/profile" eventKey="profile">
                                        <Form.Check type="radio" id="mode-dark" checked={darkMode} onClick={e => updateDarkMode(true)}
                                            label="Dark" />
                                        <Form.Check type="radio" id="mode-light" checked={!darkMode} onClick={e => updateDarkMode(false)}
                                            label="Light" />
                                        {/* <input type="radio" className="btn-check" name="options" id="mode_dark" autocomplete="off" {darkMode && checked} /> */}
                                        {/* <label className="btn btn-secondary" for="mode_dark"><FontAwesomeIcon icon={faMoon} size="lg" /> Dark</label> */}

                                        {/* <input type="radio" className="btn-check" name="options" id="mode_light" autocomplete="off" checked /> */}
                                        {/* <label className="btn btn-secondary" for="mode_light"><FontAwesomeIcon icon={faSun} size="lg" /> Light</label> */}

                                        {/* <ButtonGroup> */}
                                        {/*     <ToggleButton type="checkbox" variant={darkMode ? "dark" : "light"} value="0" */}
                                        {/*         checked={darkMode} */}
                                        {/*         onClick={e => toggleDarkMode()} */}
                                        {/*     > */}
                                        {/*         {darkMode */}
                                        {/*             ? <><FontAwesomeIcon icon={faMoon} /> Dark Side</> */}
                                        {/*             : <><FontAwesomeIcon icon={faSun} /> Light Mode</> */}
                                        {/*         } */}
                                        {/*     </ToggleButton> */}
                                        {/* </ButtonGroup> */}
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown align="end" menuVariant={darkMode ? "dark" : "light"}
                                    title={<FontAwesomeIcon icon={faUserCircle} size="lg" className={darkMode ? "text-light" : "text-dark"} />}
                                    id="nav-dropdown"
                                >
                                    <NavDropdown.Item as={Link} to="/home/profile" eventKey="profile">
                                        <FontAwesomeIcon icon={faUserCircle} fixedWidth size="lg" className="me-3" />
                                        Profile
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item eventKey="signout" className="text-danger" onClick={signOut}>
                                        <FontAwesomeIcon icon={faSignOut} fixedWidth size="lg" className="me-3" />
                                        <span>Sign Out</span>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Navbar.Collapse>
                        </>
                    )}
                </UserContext.Consumer>
            );
        }

        return <Navbar.Collapse className="justify-content-end">
            <Navbar.Text className="me-3">
                <Nav.Link href="#/signin" className="text-white">Sign in / Sign up</Nav.Link>
            </Navbar.Text>
        </Navbar.Collapse>;
    }

    return (
        <Navbar expand="md" bg={user ? darkMode ? "dark" : "light" : "primary"} variant={darkMode ? "dark" : "light"} className="p-0">
            <div className="d-flex justify-content-between ms-0 w-100">
                <div className="bg-primary align-self-stretch p-3">
                    <Navbar.Brand>
                        <Link to="/">
                            <img src={`${process.env.PUBLIC_URL}/images/logo_with_text_white.png`} alt="Billable Work" />
                            <span className="badge">beta</span>
                        </Link>
                    </Navbar.Brand>
                </div>
                <GreetOrSignIn />
            </div>
        </Navbar>
    );
}

export default Navigator;
