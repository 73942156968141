import "./App.css";

import { useState, useEffect } from "react";
import API, { graphqlOperation } from "@aws-amplify/api-graphql";
import Amplify from '@aws-amplify/core';

import { useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";
import { Container } from "react-bootstrap";
import { Route, Routes, Navigate, Outlet, HashRouter, } from "react-router-dom";

import SignIn from "./pages/signin";

import Clients from "./pages/clients";
import Navigator from "./components/navigator";
import Footer from './components/footer';
import SignUp from "./pages/signup";
import Landing from "./pages/landing";
import ClientForm from "./components/client-form";
import ContractForm from "./components/contract-form";
import Contracts from "./pages/contracts";
import Dashboard from "./pages/dashboard";
import Work from "./pages/work";
import WorkForm from "./components/work-form";
import InvoiceForm from "./components/invoice-form";
import Invoices from "./pages/invoices";
import Invoice from "./pages/invoice";
import Profile from "./pages/profile";
import ProfileForm from "./pages/profile-form";

import * as queries from "./graphql/queries";
import UserContext from "./user-context";

Amplify.configure(awsExports);

const defaultUserProfile = {
    company: {},
    theme: {
        darkMode: false
    }
};

function App() {
    const { user, route } = useAuthenticator((context) => [context.user, context.route]);

    const isAuthenticated = () => route === 'authenticated'; //user !== undefined;

    const PrivateRoute = () => {
        return isAuthenticated() ? <Outlet /> : <Navigate to="/" />;
    };

    function Home(props) {
        const [userProfile, setUserProfile] = useState(defaultUserProfile);
        const [darkMode, setDarkMode] = useState(false);
        const [isReady, setIsReady] = useState(false);

        const updateDarkMode = (newMode) => {
            setDarkMode(newMode);
            document.documentElement.setAttribute('data-bs-theme', newMode? 'dark': 'light');
        };

        useEffect(() => {
            updateDarkMode(false);
            let isActive = true;

            if (user) {
                const userEmail = user.attributes.email;
                API.graphql(
                    graphqlOperation(queries.getProfileByEmail, {
                        email: userEmail,
                    })
                )
                    .then((response) => {
                        const profiles = response.data.getProfileByEmail.items;
                        if (profiles.length > 0) {
                            if (isActive) {
                                setUserProfile(Object.assign(
                                    defaultUserProfile,
                                    profiles[0]
                                ));
                            }
                        }
                    })
                    .then(() => {
                        if (isActive) {
                            setIsReady(true);
                        }
                    });
            }

            return () => {
                isActive = false;
            };
        }, []);

        // if (!isAuthenticated()) {
        //     return 
        // }

        if (!isReady) {
            return <></>;
        }

        return (isAuthenticated()
            ? <UserContext.Provider
                value={{
                    userProfile,
                    setUserProfile,
                    darkMode,
                    updateDarkMode,
                }}
            >
                <Container
                    fluid
                    className="d-flex flex-column justify-content-between vh-100 p-0"
                >
                    <Navigator userProfile={userProfile} />
                    <div className="p-4 border border-light shadow flex-fill">
                        <PrivateRoute />
                    </div>
                    <Footer />
                </Container>
            </UserContext.Provider>
            : <Navigate to="/" />
        );
    }

    return (
        <HashRouter>
            {!isAuthenticated() ? <Navigator /> : <></>}
            <Routes>
                <Route exact path="/" element={isAuthenticated()? <Navigate to="/home" />: <Landing />} />
                <Route exact path="/signin" element={<SignIn />} />
                <Route exact path="/signup" element={<SignUp />} />
                <Route path="/home" element={<Home />}>
                    <Route exact path="" element={<Dashboard />} />
                    <Route exact path="work" element={<Work />} />
                    <Route exact path="work/new" element={<WorkForm />} />
                    <Route exact path="work/update" element={<WorkForm />} />
                    <Route exact path="clients" element={<Clients />} />
                    <Route exact path="client/new" element={<ClientForm />} />
                    <Route
                        exact
                        path="client/update"
                        element={<ClientForm />}
                    />
                    <Route exact path="contracts" element={<Contracts />} />
                    <Route
                        exact
                        path="contract/new"
                        element={<ContractForm />}
                    />
                    <Route
                        exact
                        path="contract/update"
                        element={<ContractForm />}
                    />
                    <Route exact path="invoices" element={<Invoices />} />
                    <Route exact path="invoice" element={<Invoice />} />
                    <Route exact path="invoice/new" element={<InvoiceForm />} />
                    <Route exact path="profile" element={<Profile />} />
                    <Route
                        exact
                        path="profile/edit"
                        element={<ProfileForm />}
                    />
                </Route>
            </Routes>
        </HashRouter>
    );
}

export default App;
