import { parseTime } from "./formatters";
import API, {graphqlOperation} from '@aws-amplify/api-graphql';
import * as queries from "../graphql/queries";
import { formatISODate } from "./calendar-service";
import dayjs from 'dayjs';


export function calculateWorkEarnings(work) {
    const hoursWorked = parseTime(work.timeWorked) / 60.0;
    const hourlyRate = parseInt(work.hourlyRate);

    let moneyWork = hoursWorked * hourlyRate;
    moneyWork = isNaN(moneyWork) ? 0.0 : moneyWork;

    return moneyWork / 60; // to convert to per hour
}

export async function fetchWorkForMonth(date) {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const workData = await API.graphql(graphqlOperation(queries.fetchWorkByFiscalYear, {
        fiscalYear: date.getFullYear(),
        dateWorked: {
            between:
                [
                    formatISODate(startOfMonth),
                    formatISODate(endOfMonth)
                ]
        },
    }));

    return workData.data.fetchWorkByFiscalYear.items;
}

export function fetchWorkBetween(startDate, endDate) {
    const promises = [];
    const startDay = dayjs(startDate);
    const endDay = dayjs(endDate);
    const startYear = startDay.year();
    const endYear = endDay.year();

    for (let year = startYear; year <= endYear; year++) {
        const startOfYear = dayjs(year).startOf('year');
        const endOfYear = dayjs(year).endOf('year');
        const fromDay = startYear === year? startDay: startOfYear;
        const toDay = endYear === year? endDay: endOfYear;

        const promise = API.graphql(graphqlOperation(queries.fetchWorkByFiscalYear, {
            fiscalYear: year,
            dateWorked: {
                between:
                    [
                        formatISODate(fromDay.toDate()),
                        formatISODate(toDay.toDate())
                    ]
            },
       }));
        promises.push(promise);
    }
    // const promise = API.graphql(graphqlOperation(queries.listWorks, {
    //     filter: {
    //         dateWorked: {
    //             between:
    //                 [
    //                     formatISODate(startDate),
    //                     formatISODate(endDate)
    //                 ]
    //         },
    //     }
    // }));

    return promises;
}

export function fetchWorkByFiscalYear(fiscalYear) {
    console.log('fiscal year', fiscalYear);
    const promise = API.graphql(graphqlOperation(queries.fetchWorkByFiscalYear, {
        fiscalYear: parseInt(fiscalYear)
    }));

    return promise;
}
