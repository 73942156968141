import { useState, useEffect } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import API, { graphqlOperation } from '@aws-amplify/api-graphql';
import { Container, Card, Form, ButtonGroup, Button, Row, Col, Alert, ListGroup, ListGroupItem } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { createContract, updateContract, extendContract } from "../graphql/mutations";
import { formatCurrency, formatDate, formatPercent } from "../services/formatters";

import { Badge } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { listClients } from "../graphql/queries";
import { HourlyRateDelta } from '../components/HourlyRateDelta';

const emptyContract = {
    number: undefined,
    client: undefined,
    startDate: undefined,
    endDate: undefined,
    status: 'New',
    hourlyRate: undefined
};

function ContractForm(props) {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [createOrUpdate] = useState(location.state && location.state.contract ? 'update' : 'create');
    const [formState, setFormState] = useState(createOrUpdate === 'update' ? location.state.contract : emptyContract);
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(createOrUpdate === 'update' ? location.state.contract.client : '');
    const [validated, setValidated] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [isSealed] = useState(location.state.contract.status==='Approve'? true: false);

    const navigate = useNavigate();

    const searchClients = async (query) => {
        setIsLoading(true);
        setFilteredClients(clients.filter(c => c.name.toLowerCase().includes(query.toLowerCase())));
        setIsLoading(false);
    };

    const createOrUpdateContract = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const errors = {};

        // const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //     return;
        // }

        if (!selectedClient.name) {
            errors.client = 'Please select a client!';
        }

        if (!formState.number) {
            errors.contractNumber = 'Please enter a contract number';
        }

        if (!formState.hourlyRate) {
            errors.hourlyRate = 'Hourly rate is required.';
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setValidated(false);
            return;
        }

        setValidated(true);

        try {
            const contract = { ...formState, clientID: selectedClient.id };
            contract.hourlyRate = parseInt(contract.hourlyRate * 100);
            if (createOrUpdate === 'create') {
                await API.graphql(graphqlOperation(createContract, { input: contract }));
            } else {
                if (isSealed) {
                    const clone = (({ createdAt, updatedAt, owner, client, __typename, ...o }) => o)(contract);
                    clone.clientID = selectedClient.id;
                    const contractExtension = {
                        contractId: contract.id,
                        startDate: contract.startDate,
                        endDate: contract.endDate,
                        hourlyRate: contract.hourlyRate
                    }
                    await API.graphql(graphqlOperation(extendContract, contractExtension));
                } else {
                    const clone = (({ createdAt, updatedAt, owner, client, __typename, ...o }) => o)(contract);
                    clone.clientID = selectedClient.id;
                    await API.graphql(graphqlOperation(updateContract, { input: clone }));
                }
            }

            setFormState(emptyContract);
            navigate('/home/contracts');
        } catch (error) {
            console.error('error creating contract ', error);
        }
    };

    function setInput(key, value) {
        setFormState({ ...formState, [key]: value });
    }

    useEffect(() => {
        if (formState.hourlyRate) {
            formState.hourlyRate = (formState.hourlyRate * 1).toFixed(2);
        }

        const listClientsPromise = API.graphql(graphqlOperation(listClients));

        (async () => {
            const clientsData = await listClientsPromise;
            const clients = clientsData.data.listClients.items;
            setClients(clients);
        })();

        return () => {
            API.cancel(listClientsPromise);
        };
    }, []);

    // function HourlyRateDelta(){
    //     let arrow = faArrowAltUp;
    //     let arrowColor = 'text-success';

    //     if (formState.extension.hourlyRateDelta < 0) {
    //         arrow = faArrowAltDown;
    //         arrowColor = 'text-danger';
    //     }

    //     return (
    //         <>
    //             <FontAwesomeIcon icon={arrow} className={`ms-1 ${arrowColor}`} /> {formatPercent(formState.extension.hourlyRateDelta)}
    //         </>
    //     )
    // }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Card style={{ "width": "40rem" }}>
                        <Card.Header>{createOrUpdate === 'update' ? 'Update' : 'New'} Contract</Card.Header>
                        <Card.Body>
                            {isSealed &&
                                <Alert>
                                    Since this contract has been approved, updating the terms will cause an amendment to be created and attached to the original contract.
                                </Alert>
                            }
                            <Form noValidate validated={validated} onSubmit={createOrUpdateContract}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicNumber">
                                            <Form.Label>Contract number</Form.Label>
                                            <Form.Control required type="text" placeholder="Contract number"
                                                onChange={event => setInput('number', event.target.value)}
                                                value={formState.number}
                                                isInvalid={!!formErrors.contractNumber} disabled={isSealed}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicClient">
                                            <Form.Label>Client</Form.Label>
                                            <AsyncTypeahead
                                                required
                                                id="client-typeahead"
                                                filterBy={() => true}
                                                isLoading={isLoading}
                                                labelKey="name"
                                                minLength={2}
                                                onSearch={searchClients}
                                                onChange={selected => { setSelectedClient(selected[0]) }}
                                                options={filteredClients}
                                                placeholder="Start typing for client..."
                                                defaultInputValue={selectedClient.name}
                                                //selected={[selectedClient]}
                                                isInvalid={!!formErrors.client}
                                                disabled={isSealed}
                                            // TODO: Nice example on how to custom render items!!
                                            // renderMenuItemChildren={(option) => (
                                            //     <>
                                            //         <img
                                            //             alt={option.login}
                                            //             src={option.avatar_url}
                                            //             style={{
                                            //                 height: '24px',
                                            //                 marginRight: '10px',
                                            //                 width: '24px',
                                            //             }}
                                            //         />
                                            //         <span>{option.login}</span>
                                            //     </>
                                            // )}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please select a registered client
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicRate">
                                            <Form.Label>Rate per hour ($)</Form.Label>
                                            <Form.Control required type="number" placeholder="Hourly rate"
                                                onChange={event => setInput('hourlyRate', event.target.value)}
                                                value={(formState.hourlyRate)}
                                                isInvalid={!!formErrors.hourlyRate} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicStartDate">
                                            <Form.Label>Effective date</Form.Label>
                                            <Form.Control type="date" placeholder="Effective date"
                                                onChange={event => setInput('startDate', event.target.value)}
                                                value={formState.startDate} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEndDate">
                                            <Form.Label>Expiration date</Form.Label>
                                            <Form.Control type="date" placeholder="Expiration date"
                                                onChange={event => setInput('endDate', event.target.value)}
                                                value={formState.endDate} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <ButtonGroup className="w-100">
                                    <Button as={Link} to="/home/contracts" variant="secondary-outline" type="button">
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type="submit">Save</Button>
                                </ButtonGroup>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="d-flex justify-content-start align-items-start"
                    style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/undraw_agreement_re_d4dv.svg")`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                    { formState.extension &&
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                {formState.extension.title}
                                <Badge bg="primary">{formState.extension.status}</Badge>
                            </Card.Header>
                            <Card.Body>
                                <ListGroup variant="flush">
                                    <ListGroupItem><span className="border px-2 py-1 bg-light">Start Date</span> {formatDate(new Date(formState.extension.startDate))}</ListGroupItem>
                                    <ListGroupItem><span className="border px-2 py-1 bg-light">End Date</span> {formatDate(new Date(formState.extension.endDate))}</ListGroupItem>
                                    <ListGroupItem><span className="border px-2 py-1 bg-light">Hourly Rate</span> {formatCurrency(formState.extension.hourlyRate)} (<HourlyRateDelta extension={formState.extension}/>)</ListGroupItem>
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    }
                </Col>
            </Row>
        </Container>
    );
}

export default withAuthenticator(ContractForm);
