//import { isAHoliday, inRange } from "@18f/us-federal-holidays";
import { isAHoliday, inRange } from "./us-federal-holidays";
import dayjs from 'dayjs';

export function startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
}

export function endOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
}

export function parseISODateString(isoDate) {
    const components = isoDate.split('-');

    return new Date(components[0], components[1] - 1, components[2]);
}

export function formatISODate(date) {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
}


export function countWorkingDaysYear(date) {
    let countWorkDays = 0;

    const thisYear = dayjs();
    let month = dayjs().startOf('year');
    while (month.isSame(thisYear, 'year')) {
        countWorkDays += countWorkingDaysMonth(month.toDate());
        month = month.add(1, 'month');
    }

    return countWorkDays;
}

export function countWorkingDaysMonth(date) {
    let startDate = startOfMonth(date);
    const endDate = endOfMonth(date);
    let numberOfWorkDays = 0;

    while (startDate <= endDate) {
        if (isWeekday(startDate) && !isAHoliday(startDate)){
            numberOfWorkDays++;
        }

        startDate.setDate(startDate.getDate() + 1);
    }

    return numberOfWorkDays;
}

export function holidaysInMonth(date) {
    const startDate = startOfMonth(date);
    const endDate = endOfMonth(date);

    return inRange(startDate, endDate);
}

export function holidaysBetween(startDate, endDate) {
    return inRange(startDate, endDate);
}

export function isWeekday(date) {
    return !isWeekend(date);
}

export function isWeekend(date) {
    return date.getDay() % 6 === 0;
}
