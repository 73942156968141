import { withAuthenticator } from "@aws-amplify/ui-react";
import { useState } from "react";
import { Card, Col, Container, Nav, Row, Alert } from "react-bootstrap";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContractWorkForm from "./contract-work-form";
import AdhocWorkForm from "./adhoc-work-form";

function WorkForm(props) {
    const [workType, setWorkType] = useState('contract');
    const [error, setError] = useState('');

    return (
        <Container fluid>
            <h1>Add Work</h1>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Nav variant="tabs" className="nav-fill" defaultActiveKey="contract"
                                activeKey={workType} onSelect={selected => setWorkType(selected)}>
                                <Nav.Item>
                                    <Nav.Link eventKey="contract">Contract Work</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="adhoc">Ad Hoc Work</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Card.Header>
                        <Card.Body>
                            {workType === 'contract' && <ContractWorkForm onError={setError} />}
                            {workType === 'adhoc' && <AdhocWorkForm /> }
                        </Card.Body>
                    </Card>
                </Col>
              <Col className="d-flex flex-column justify-content-between align-items-center" style={{backgroundImage: `url("${process.env.PUBLIC_URL}/images/undraw_working_re_ddwy.svg")`, backgroundSize:'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
                    <h1 className="text-center bg-light bg-opacity-50 rounded-bottom">
                        Work hard, play hard, <br/> have some fun.
                    </h1>
                    { error &&
                        <Alert variant="danger">
                            <Alert.Heading>
                                <FontAwesomeIcon icon={faExclamationTriangle} /> Oops, something is wrong
                            </Alert.Heading>
                            <hr />
                            <p>{error}</p>
                        </Alert>
                    }
                </Col>
            </Row>
        </Container>
    );
}

export default withAuthenticator(WorkForm);
