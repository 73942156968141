import { withAuthenticator } from "@aws-amplify/ui-react";
import API, {graphqlOperation} from '@aws-amplify/api-graphql';
import { useEffect, useState } from "react";
import {
    Button,
    ButtonGroup,
    Col,
    Row,
    Table,
    OverlayTrigger,
    Tooltip,
    Modal
} from "react-bootstrap";
import { Link } from "react-router-dom";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { formatCurrency, formatDate, formatInvoiceNumber } from "../services/formatters";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faDollarSign, faSync, faCaretDown, faCaretUp } from "@fortawesome/pro-light-svg-icons";

function Invoices(props) {
    const [invoices, setInvoices] = useState([]);
    const [unpaidCount, setUnpaidCount] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [activeInvoice, setActiveInvoice] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [sortDirection, setSortDirection] = useState('desc');
    const [sortBy, setSortBy] = useState('startDate');

    useEffect(() => {
        const listInvoicesPromise = API.graphql(graphqlOperation(queries.listInvoices));

        (async () => {
            try {
                await reloadAll();
                // const listInvoicesResponse = await listInvoicesPromise;
                // const invoices = listInvoicesResponse.data.listInvoices.items;

                // setInvoices(invoices);
            } catch (err) {
                if (!API.isCancel(err)) {
                    console.error(err);
                }
            }
        })();

        return () => {
            API.cancel(listInvoicesPromise);
        };
    }, []);

    function sortByInvoiceNumber(a,b) {
        let invoiceNumberA = parseInt(a.invoiceNumber);
        let invoiceNumberB = parseInt(b.invoiceNumber);
        if (sortDirection === 'asc') {
            invoiceNumberA = parseInt(b.invoiceNumber);
            invoiceNumberB = parseInt(a.invoiceNumber);
        }

        return invoiceNumberA - invoiceNumberB;
    }

    function sortByClient(a, b) {
        let clientNameA = a.client.name.toUpperCase();
        let clientNameB = b.client.name.toUpperCase();
        if (sortDirection === 'asc') {
            clientNameA = b.client.name.toUpperCase();
            clientNameB = a.client.name.toUpperCase();
        }

        if (clientNameA > clientNameB) { return -1; }
        if (clientNameA < clientNameB) { return 1; }
        return sortByInvoiceNumber(a, b);
    }

    function sortByStartDate(a, b) {
        return sortByDate(a.startDate, b.startDate, sortDirection);
    }

    function sortInvoices(a, b) {
        if (sortBy === 'client') {
            return sortByClient(a,b);
        }

        return sortByStartDate(a,b);
    }

    function sortByDate(d1, d2, direction) {
        let a = d1;
        let b = d2;
        if (direction === 'desc') {
            a = d2;
            b = d1;
        }

        if (a > b) { return 1; }
        if (a < b) { return -1; }
        return 0;
    }

    function updateSortInfo(sortBy, direction) {
        setSortBy(sortBy);
        setSortDirection(direction);
    }

    const markPaid = async (invoice) => {
        try {
            await API.graphql(
                graphqlOperation(mutations.updateInvoice, {
                    input: {
                        id: invoice.id,
                        isPaid: true,
                    },
                })
            );

            const dups = invoices;
            const index = dups.findIndex((inv) => inv.id === invoice.id);
            if (index > -1) {
                const inv = dups[index];
                inv.isPaid = true;
                dups[index] = inv;
                setInvoices([...dups]);
            }
            console.log("Mark Paid for ", invoice.id);
        } catch (error) {
            console.error(error);
        }
    };

    const confirmDelete = (invoice) => {
        setActiveInvoice(invoice);
        setShowDeleteModal(true);
    };

    const deleteInvoice = async () => {
        if (!activeInvoice) {
            return;
        }

        try {
            await API.graphql(graphqlOperation(mutations.deleteInvoice, { input: { id: activeInvoice.id } }));
            setInvoices(invoices.filter(inv => inv.id !== activeInvoice.id));
            setShowDeleteModal(false);
        } catch (error) {
            console.error(error);
        }
    };

    const reloadAll = async () => {
        setIsLoading(true);
        try {
            const getInvoiceResponse = await API.graphql(graphqlOperation(queries.listInvoices));
            const invoices = getInvoiceResponse.data.listInvoices.items;

            const unpaidCount = invoices.reduce((unpaidCount) => unpaidCount+1, 0);
            setUnpaidCount(unpaidCount);

            setInvoices(invoices);
        } catch (error){
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="d-flex align-items-start">
                <h1 className="h1 title text-primary">Invoices</h1>
                <h4 class="badge rounded-pill bg-info">
                    {unpaidCount}
                    <span class="visually-hidden">unpaid invoices</span>
                </h4>
            </div>
            <Row className="pt-3">
                <Col className="d-flex justify-content-end">
                    <Button variant="primary" as={Link} to="/home/invoice/new">
                        New Invoice
                    </Button>
                </Col>
            </Row>
            <Row xs={1} md={3} className="mt-2 p-4">
                <Table>
                    <thead>
                        <tr>
                            <th>Invoice ID</th>
                          <th>
                            <div className="d-flex justify-content-between align-items-end">
                                Client
                                <span className="d-flex flex-column">
                                    <FontAwesomeIcon icon={faCaretUp}
                                                    className={sortDirection==='asc'?"text-primary":"text-dark"}
                                                    size={sortBy==='client' && sortDirection==='asc'?"lg":"sm"}
                                                    onClick={() => updateSortInfo('client', 'asc')} />
                                    <FontAwesomeIcon icon={faCaretDown}
                                                    className={sortDirection==='desc'?"text-primary":"text-dark"}
                                                    size={sortBy==='client' && sortDirection==='desc'?"lg":"sm"}
                                                    onClick={() => updateSortInfo('client', 'desc')} />
                                </span>
                              </div>
                            </th>
                          <th>
                            <div className="d-flex justify-content-between align-items-end">
                                From Date
                                <span className="d-flex flex-column">
                                    <FontAwesomeIcon icon={faCaretUp}
                                                    className={sortDirection==='asc'?"text-primary":"text-dark"}
                                                    size={sortBy==='startDate' && sortDirection==='asc'?"lg":"sm"}
                                                    onClick={() => updateSortInfo('startDate', 'asc')} />
                                    <FontAwesomeIcon icon={faCaretDown}
                                                    className={sortDirection==='desc'?"text-primary":"text-dark"}
                                                    size={sortBy==='startDate' && sortDirection==='desc'?"lg":"sm"}
                                                    onClick={() => updateSortInfo('startDate', 'desc')} />
                                </span>
                            </div>
                            </th>
                            <th>To Date</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th className="text-end">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                            <Tooltip id="reload-button-tooltip">
                                                Reload
                                            </Tooltip>
                                    }
                                >
                                    <Button variant="outline-secondary" size="sm" onClick={() => reloadAll()}>
                                    { isLoading
                                        ? <FontAwesomeIcon icon={faSync} spin fixedWidth />
                                        : <FontAwesomeIcon icon={faSync} fixedWidth />
                                    }
                                    </Button> 
                                </OverlayTrigger>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                      {invoices.sort(sortInvoices).map((invoice) => {
                            return (
                                <tr key={invoice.id}>
                                    <td>
                                        <Link to="/home/invoice" state={{invoiceId: invoice.id}}> {invoice.invoiceNumber === '-1'
                                            ? "Not Available"
                                            : formatInvoiceNumber(invoice.invoiceNumber)}
                                        </Link>
                                    </td>
                                    <td>{invoice.client.name}</td>
                                    <td>
                                        {formatDate(
                                            dayjs(invoice.startDate).toDate()
                                        )}
                                    </td>
                                    <td>
                                        {formatDate(
                                            dayjs(invoice.endDate).toDate()
                                        )}
                                    </td>
                                    <td>
                                        {formatCurrency(invoice.invoiceAmount)}
                                    </td>
                                    <td>
                                        {invoice.isPaid ? "Paid" : "Unpaid"}
                                    </td>
                                    <td className="d-flex justify-content-between">
                                        <ButtonGroup size="sm">
                                            {!invoice.isPaid && (
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="paid-button-tooltip">
                                                            Mark paid
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() =>
                                                            markPaid(invoice)
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faDollarSign}
                                                            fixedWidth
                                                        />
                                                    </Button>
                                                </OverlayTrigger>
                                            )}
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="delete-button-tooltip">
                                                        Delete
                                                    </Tooltip>
                                                }
                                            >
                                                <Button variant="outline-danger" onClick={() => confirmDelete(invoice)}>
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                        fixedWidth
                                                    />
                                                </Button>
                                            </OverlayTrigger>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Row>
            <Modal
                show={showDeleteModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this invoice?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        No, cancel
                    </Button>
                    <Button variant="danger" onClick={deleteInvoice}>Yes</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default withAuthenticator(Invoices);
