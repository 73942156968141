/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      name
      email
      phone
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fetchClientByName = /* GraphQL */ `
  query FetchClientByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fetchClientByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContract = /* GraphQL */ `
  query GetContract($id: ID!) {
    getContract(id: $id) {
      id
      number
      clientID
      client {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      startDate
      endDate
      hourlyRate
      status
      amendmentCount
      extensionID
      extension {
        id
        title
        startDate
        endDate
        hourlyRate
        hourlyRateDelta
        contractID
        status
        owner
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContracts = /* GraphQL */ `
  query ListContracts(
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        clientID
        client {
          id
          name
          email
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        startDate
        endDate
        hourlyRate
        status
        amendmentCount
        extensionID
        extension {
          id
          title
          startDate
          endDate
          hourlyRate
          hourlyRateDelta
          contractID
          status
          owner
          createdAt
          updatedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fetchContractByStatus = /* GraphQL */ `
  query FetchContractByStatus(
    $status: ContractStatus!
    $sortDirection: ModelSortDirection
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fetchContractByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        clientID
        client {
          id
          name
          email
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        startDate
        endDate
        hourlyRate
        status
        amendmentCount
        extensionID
        extension {
          id
          title
          startDate
          endDate
          hourlyRate
          hourlyRateDelta
          contractID
          status
          owner
          createdAt
          updatedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContractExtension = /* GraphQL */ `
  query GetContractExtension($id: ID!) {
    getContractExtension(id: $id) {
      id
      title
      startDate
      endDate
      hourlyRate
      hourlyRateDelta
      contractID
      status
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContractExtensions = /* GraphQL */ `
  query ListContractExtensions(
    $filter: ModelContractExtensionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractExtensions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        startDate
        endDate
        hourlyRate
        hourlyRateDelta
        contractID
        status
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWork = /* GraphQL */ `
  query GetWork($id: ID!) {
    getWork(id: $id) {
      id
      clientID
      client {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      contractID
      contract {
        id
        number
        clientID
        client {
          id
          name
          email
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        startDate
        endDate
        hourlyRate
        status
        amendmentCount
        extensionID
        extension {
          id
          title
          startDate
          endDate
          hourlyRate
          hourlyRateDelta
          contractID
          status
          owner
          createdAt
          updatedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      dateWorked
      fiscalYear
      hourlyRate
      timeWorked
      notes
      invoiceID
      invoice {
        id
        invoiceNumber
        work {
          items {
            id
            clientID
            contractID
            dateWorked
            fiscalYear
            hourlyRate
            timeWorked
            notes
            invoiceID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        clientID
        client {
          id
          name
          email
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        startDate
        endDate
        invoiceAmount
        isPaid
        paymentSource
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listWorks = /* GraphQL */ `
  query ListWorks(
    $filter: ModelWorkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientID
        client {
          id
          name
          email
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        contractID
        contract {
          id
          number
          clientID
          client {
            id
            name
            email
            phone
            createdAt
            updatedAt
            owner
            __typename
          }
          startDate
          endDate
          hourlyRate
          status
          amendmentCount
          extensionID
          extension {
            id
            title
            startDate
            endDate
            hourlyRate
            hourlyRateDelta
            contractID
            status
            owner
            createdAt
            updatedAt
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        dateWorked
        fiscalYear
        hourlyRate
        timeWorked
        notes
        invoiceID
        invoice {
          id
          invoiceNumber
          work {
            nextToken
            __typename
          }
          clientID
          client {
            id
            name
            email
            phone
            createdAt
            updatedAt
            owner
            __typename
          }
          startDate
          endDate
          invoiceAmount
          isPaid
          paymentSource
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fetchWorkByFiscalYear = /* GraphQL */ `
  query FetchWorkByFiscalYear(
    $fiscalYear: Int!
    $dateWorked: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fetchWorkByFiscalYear(
      fiscalYear: $fiscalYear
      dateWorked: $dateWorked
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientID
        client {
          id
          name
          email
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        contractID
        contract {
          id
          number
          clientID
          client {
            id
            name
            email
            phone
            createdAt
            updatedAt
            owner
            __typename
          }
          startDate
          endDate
          hourlyRate
          status
          amendmentCount
          extensionID
          extension {
            id
            title
            startDate
            endDate
            hourlyRate
            hourlyRateDelta
            contractID
            status
            owner
            createdAt
            updatedAt
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        dateWorked
        fiscalYear
        hourlyRate
        timeWorked
        notes
        invoiceID
        invoice {
          id
          invoiceNumber
          work {
            nextToken
            __typename
          }
          clientID
          client {
            id
            name
            email
            phone
            createdAt
            updatedAt
            owner
            __typename
          }
          startDate
          endDate
          invoiceAmount
          isPaid
          paymentSource
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      invoiceNumber
      work {
        items {
          id
          clientID
          client {
            id
            name
            email
            phone
            createdAt
            updatedAt
            owner
            __typename
          }
          contractID
          contract {
            id
            number
            clientID
            startDate
            endDate
            hourlyRate
            status
            amendmentCount
            extensionID
            owner
            createdAt
            updatedAt
            __typename
          }
          dateWorked
          fiscalYear
          hourlyRate
          timeWorked
          notes
          invoiceID
          invoice {
            id
            invoiceNumber
            clientID
            startDate
            endDate
            invoiceAmount
            isPaid
            paymentSource
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      clientID
      client {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      startDate
      endDate
      invoiceAmount
      isPaid
      paymentSource
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        invoiceNumber
        work {
          items {
            id
            clientID
            contractID
            dateWorked
            fiscalYear
            hourlyRate
            timeWorked
            notes
            invoiceID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        clientID
        client {
          id
          name
          email
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        startDate
        endDate
        invoiceAmount
        isPaid
        paymentSource
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      email
      firstName
      lastName
      companyID
      company {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        firstName
        lastName
        companyID
        company {
          id
          name
          email
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProfileByEmail = /* GraphQL */ `
  query GetProfileByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProfileByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        companyID
        company {
          id
          name
          email
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProfileCompany = /* GraphQL */ `
  query GetProfileCompany($id: ID!) {
    getProfileCompany(id: $id) {
      id
      name
      email
      phone
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listProfileCompanies = /* GraphQL */ `
  query ListProfileCompanies(
    $filter: ModelProfileCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileCompanies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWorkSummary = /* GraphQL */ `
  query GetWorkSummary($id: ID!, $yearMonth: Int!) {
    getWorkSummary(id: $id, yearMonth: $yearMonth) {
      id
      yearMonth
      year
      month
      totalIncome
      totalTimeWorked
      workCount
      sumHourlyRate
      incomeByClient
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listWorkSummaries = /* GraphQL */ `
  query ListWorkSummaries(
    $id: ID
    $yearMonth: ModelIntKeyConditionInput
    $filter: ModelWorkSummaryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWorkSummaries(
      id: $id
      yearMonth: $yearMonth
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        yearMonth
        year
        month
        totalIncome
        totalTimeWorked
        workCount
        sumHourlyRate
        incomeByClient
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
