import { useState, useEffect } from 'react';
import { withAuthenticator } from "@aws-amplify/ui-react";
import Auth from '@aws-amplify/auth';
import API, {graphqlOperation} from '@aws-amplify/api-graphql';
import * as queries from '../graphql/queries';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Profile(props) {
	const [profile, setProfile] = useState({company: {}});
	const [currentUserEmail, setCurrentUserEmail] = useState('');

	useEffect(() => {
		let componentIsActive = true;
		let getProfileByEmailPromise = undefined;
		let getProfileCompanyPromise = undefined;
		const currentUserInfoPromise = Auth.currentUserInfo();

		(async () => {
			const currentUserInfo = await currentUserInfoPromise;
			if (componentIsActive) {
				const currentUserEmail = currentUserInfo.attributes.email;
				setCurrentUserEmail(currentUserEmail);
				getProfileByEmailPromise = API.graphql(graphqlOperation(queries.getProfileByEmail, {email: currentUserEmail}));
				const getProfileByEmailResponse = await getProfileByEmailPromise;
				const profiles = getProfileByEmailResponse.data.getProfileByEmail.items;
				if (profiles.length > 0) {
					const userProfile = profiles[0];
					if (!userProfile.company) {
						userProfile['company'] = {};
					}
					setProfile(userProfile);
				}
			}
		})();

		return () => {
			componentIsActive = false;
			if (getProfileByEmailPromise) {
				API.cancel(getProfileByEmailPromise);
				API.cancel(getProfileCompanyPromise);
			}
		};
	}, []);

	return (
		<>
            <span className="h1 title text-primary">Profile</span>
	        <Row>
	            <Col className="d-flex justify-content-end">
	                <Button variant="primary" as={Link} to="/home/profile/edit" state={{profile, currentUserEmail}}>
	                    Edit profile
	                </Button>
	            </Col>
	        </Row>
			<section>
				<h5 className="border-bottom text-muted">Personal</h5>
				<Row className="ms-2 pt-4">
					<Col md="3"><h6>First Name</h6></Col>
					<Col>{profile.firstName || 'Not Available'}</Col>
				</Row>
				<Row className="ms-2 pt-4">
					<Col md="3"><h6>Last Name</h6></Col>
					<Col>{profile.lastName || 'Not Available'}</Col>
				</Row>
				<Row className="ms-2 pt-4">
					<Col md="3"><h6>Email</h6></Col>
					<Col>{currentUserEmail || 'Not Available'}</Col>
				</Row>
			</section>
			<section className="mt-5">
				<h5 className="border-bottom text-muted">Company / Organization</h5>
				<Row className="ms-2 pt-4">
					<Col md="3"><h6>Company Name</h6></Col>
					<Col>{profile.company.name || 'Not Available'}</Col>
				</Row>
				<Row className="ms-2 pt-4">
					<Col md="3"><h6>Email</h6></Col>
					<Col>{profile.company.email || 'Not Available'}</Col>
				</Row>
				<Row className="ms-2 pt-4">
					<Col md="3"><h6>Phone</h6></Col>
					<Col>{profile.company.phone || 'Not Available'}</Col>
				</Row>
			</section>
		</>
	);
}

export default withAuthenticator(Profile);
