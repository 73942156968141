import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './footer.css';

function Footer(props) {
    {/*<footer className="footer mt-auto py-3 bg-light">*/ }
    return (
        <footer className="row section bg-secondary text-light py-2 ps-5 pe-2" >
            <Container className="d-flex justify-content-between align-items-end">
                <img src={`${process.env.PUBLIC_URL}/images/Billable Work Vertical Transparent@165.png`} alt="logo with text" />
                <ul className="text-light link">
                    <li><Link to="/" className="text-light">Home</Link></li>
                    <li><a href="https://www.iubenda.com/privacy-policy/51633433" target="_blank"
                        className="text-light"
                        title="Privacy Policy ">Privacy Policy</a>
                    </li>
                    <li>
                        <a href="https://www.iubenda.com/terms-and-conditions/51633433" target="_blank"
                            className="text-light"
                            title="Terms and Conditions ">Terms and Conditions</a>
                    </li>
                    <li>
                        <a href="https://www.iubenda.com/privacy-policy/51633433/cookie-policy" target="_blank"
                            className="text-light"
                            title="Cookie Policy ">Cookie Policy</a>
                    </li>
                </ul>
            </Container>
            <Container className="border-top">
                <span>Copyright &copy; 2020 Work Smarter Computing LLC. All rights reserved.</span>
            </Container>
        </footer >
    );
}

export default Footer;

