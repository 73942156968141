/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      name
      email
      phone
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      name
      email
      phone
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      name
      email
      phone
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createContract = /* GraphQL */ `
  mutation CreateContract(
    $input: CreateContractInput!
    $condition: ModelContractConditionInput
  ) {
    createContract(input: $input, condition: $condition) {
      id
      number
      clientID
      client {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      startDate
      endDate
      hourlyRate
      status
      amendmentCount
      extensionID
      extension {
        id
        title
        startDate
        endDate
        hourlyRate
        hourlyRateDelta
        contractID
        status
        owner
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContract = /* GraphQL */ `
  mutation UpdateContract(
    $input: UpdateContractInput!
    $condition: ModelContractConditionInput
  ) {
    updateContract(input: $input, condition: $condition) {
      id
      number
      clientID
      client {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      startDate
      endDate
      hourlyRate
      status
      amendmentCount
      extensionID
      extension {
        id
        title
        startDate
        endDate
        hourlyRate
        hourlyRateDelta
        contractID
        status
        owner
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContract = /* GraphQL */ `
  mutation DeleteContract(
    $input: DeleteContractInput!
    $condition: ModelContractConditionInput
  ) {
    deleteContract(input: $input, condition: $condition) {
      id
      number
      clientID
      client {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      startDate
      endDate
      hourlyRate
      status
      amendmentCount
      extensionID
      extension {
        id
        title
        startDate
        endDate
        hourlyRate
        hourlyRateDelta
        contractID
        status
        owner
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContractExtension = /* GraphQL */ `
  mutation CreateContractExtension(
    $input: CreateContractExtensionInput!
    $condition: ModelContractExtensionConditionInput
  ) {
    createContractExtension(input: $input, condition: $condition) {
      id
      title
      startDate
      endDate
      hourlyRate
      hourlyRateDelta
      contractID
      status
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContractExtension = /* GraphQL */ `
  mutation UpdateContractExtension(
    $input: UpdateContractExtensionInput!
    $condition: ModelContractExtensionConditionInput
  ) {
    updateContractExtension(input: $input, condition: $condition) {
      id
      title
      startDate
      endDate
      hourlyRate
      hourlyRateDelta
      contractID
      status
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContractExtension = /* GraphQL */ `
  mutation DeleteContractExtension(
    $input: DeleteContractExtensionInput!
    $condition: ModelContractExtensionConditionInput
  ) {
    deleteContractExtension(input: $input, condition: $condition) {
      id
      title
      startDate
      endDate
      hourlyRate
      hourlyRateDelta
      contractID
      status
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWork = /* GraphQL */ `
  mutation CreateWork(
    $input: CreateWorkInput!
    $condition: ModelWorkConditionInput
  ) {
    createWork(input: $input, condition: $condition) {
      id
      clientID
      client {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      contractID
      contract {
        id
        number
        clientID
        client {
          id
          name
          email
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        startDate
        endDate
        hourlyRate
        status
        amendmentCount
        extensionID
        extension {
          id
          title
          startDate
          endDate
          hourlyRate
          hourlyRateDelta
          contractID
          status
          owner
          createdAt
          updatedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      dateWorked
      fiscalYear
      hourlyRate
      timeWorked
      notes
      invoiceID
      invoice {
        id
        invoiceNumber
        work {
          items {
            id
            clientID
            contractID
            dateWorked
            fiscalYear
            hourlyRate
            timeWorked
            notes
            invoiceID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        clientID
        client {
          id
          name
          email
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        startDate
        endDate
        invoiceAmount
        isPaid
        paymentSource
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateWork = /* GraphQL */ `
  mutation UpdateWork(
    $input: UpdateWorkInput!
    $condition: ModelWorkConditionInput
  ) {
    updateWork(input: $input, condition: $condition) {
      id
      clientID
      client {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      contractID
      contract {
        id
        number
        clientID
        client {
          id
          name
          email
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        startDate
        endDate
        hourlyRate
        status
        amendmentCount
        extensionID
        extension {
          id
          title
          startDate
          endDate
          hourlyRate
          hourlyRateDelta
          contractID
          status
          owner
          createdAt
          updatedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      dateWorked
      fiscalYear
      hourlyRate
      timeWorked
      notes
      invoiceID
      invoice {
        id
        invoiceNumber
        work {
          items {
            id
            clientID
            contractID
            dateWorked
            fiscalYear
            hourlyRate
            timeWorked
            notes
            invoiceID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        clientID
        client {
          id
          name
          email
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        startDate
        endDate
        invoiceAmount
        isPaid
        paymentSource
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteWork = /* GraphQL */ `
  mutation DeleteWork(
    $input: DeleteWorkInput!
    $condition: ModelWorkConditionInput
  ) {
    deleteWork(input: $input, condition: $condition) {
      id
      clientID
      client {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      contractID
      contract {
        id
        number
        clientID
        client {
          id
          name
          email
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        startDate
        endDate
        hourlyRate
        status
        amendmentCount
        extensionID
        extension {
          id
          title
          startDate
          endDate
          hourlyRate
          hourlyRateDelta
          contractID
          status
          owner
          createdAt
          updatedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      dateWorked
      fiscalYear
      hourlyRate
      timeWorked
      notes
      invoiceID
      invoice {
        id
        invoiceNumber
        work {
          items {
            id
            clientID
            contractID
            dateWorked
            fiscalYear
            hourlyRate
            timeWorked
            notes
            invoiceID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        clientID
        client {
          id
          name
          email
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        startDate
        endDate
        invoiceAmount
        isPaid
        paymentSource
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      invoiceNumber
      work {
        items {
          id
          clientID
          client {
            id
            name
            email
            phone
            createdAt
            updatedAt
            owner
            __typename
          }
          contractID
          contract {
            id
            number
            clientID
            startDate
            endDate
            hourlyRate
            status
            amendmentCount
            extensionID
            owner
            createdAt
            updatedAt
            __typename
          }
          dateWorked
          fiscalYear
          hourlyRate
          timeWorked
          notes
          invoiceID
          invoice {
            id
            invoiceNumber
            clientID
            startDate
            endDate
            invoiceAmount
            isPaid
            paymentSource
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      clientID
      client {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      startDate
      endDate
      invoiceAmount
      isPaid
      paymentSource
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      invoiceNumber
      work {
        items {
          id
          clientID
          client {
            id
            name
            email
            phone
            createdAt
            updatedAt
            owner
            __typename
          }
          contractID
          contract {
            id
            number
            clientID
            startDate
            endDate
            hourlyRate
            status
            amendmentCount
            extensionID
            owner
            createdAt
            updatedAt
            __typename
          }
          dateWorked
          fiscalYear
          hourlyRate
          timeWorked
          notes
          invoiceID
          invoice {
            id
            invoiceNumber
            clientID
            startDate
            endDate
            invoiceAmount
            isPaid
            paymentSource
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      clientID
      client {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      startDate
      endDate
      invoiceAmount
      isPaid
      paymentSource
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      invoiceNumber
      work {
        items {
          id
          clientID
          client {
            id
            name
            email
            phone
            createdAt
            updatedAt
            owner
            __typename
          }
          contractID
          contract {
            id
            number
            clientID
            startDate
            endDate
            hourlyRate
            status
            amendmentCount
            extensionID
            owner
            createdAt
            updatedAt
            __typename
          }
          dateWorked
          fiscalYear
          hourlyRate
          timeWorked
          notes
          invoiceID
          invoice {
            id
            invoiceNumber
            clientID
            startDate
            endDate
            invoiceAmount
            isPaid
            paymentSource
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      clientID
      client {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      startDate
      endDate
      invoiceAmount
      isPaid
      paymentSource
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      companyID
      company {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      companyID
      company {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      companyID
      company {
        id
        name
        email
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createProfileCompany = /* GraphQL */ `
  mutation CreateProfileCompany(
    $input: CreateProfileCompanyInput!
    $condition: ModelProfileCompanyConditionInput
  ) {
    createProfileCompany(input: $input, condition: $condition) {
      id
      name
      email
      phone
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateProfileCompany = /* GraphQL */ `
  mutation UpdateProfileCompany(
    $input: UpdateProfileCompanyInput!
    $condition: ModelProfileCompanyConditionInput
  ) {
    updateProfileCompany(input: $input, condition: $condition) {
      id
      name
      email
      phone
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteProfileCompany = /* GraphQL */ `
  mutation DeleteProfileCompany(
    $input: DeleteProfileCompanyInput!
    $condition: ModelProfileCompanyConditionInput
  ) {
    deleteProfileCompany(input: $input, condition: $condition) {
      id
      name
      email
      phone
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createWorkSummary = /* GraphQL */ `
  mutation CreateWorkSummary(
    $input: CreateWorkSummaryInput!
    $condition: ModelWorkSummaryConditionInput
  ) {
    createWorkSummary(input: $input, condition: $condition) {
      id
      yearMonth
      year
      month
      totalIncome
      totalTimeWorked
      workCount
      sumHourlyRate
      incomeByClient
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateWorkSummary = /* GraphQL */ `
  mutation UpdateWorkSummary(
    $input: UpdateWorkSummaryInput!
    $condition: ModelWorkSummaryConditionInput
  ) {
    updateWorkSummary(input: $input, condition: $condition) {
      id
      yearMonth
      year
      month
      totalIncome
      totalTimeWorked
      workCount
      sumHourlyRate
      incomeByClient
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteWorkSummary = /* GraphQL */ `
  mutation DeleteWorkSummary(
    $input: DeleteWorkSummaryInput!
    $condition: ModelWorkSummaryConditionInput
  ) {
    deleteWorkSummary(input: $input, condition: $condition) {
      id
      yearMonth
      year
      month
      totalIncome
      totalTimeWorked
      workCount
      sumHourlyRate
      incomeByClient
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const extendContract = /* GraphQL */ `
  mutation ExtendContract(
    $contractId: ID!
    $startDate: AWSDate!
    $endDate: AWSDate!
    $hourlyRate: Int
  ) {
    extendContract(
      contractId: $contractId
      startDate: $startDate
      endDate: $endDate
      hourlyRate: $hourlyRate
    ) {
      id
      title
      startDate
      endDate
      hourlyRate
      hourlyRateDelta
      contractID
      status
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
