import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { Authenticator } from '@aws-amplify/ui-react';

import '../node_modules/bootstrap//dist/css/bootstrap.min.css';

Amplify.configure(awsExports);

ReactDOM.render(
    <React.StrictMode>
        <Authenticator.Provider>
            <App />
        </Authenticator.Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
