import { withAuthenticator } from "@aws-amplify/ui-react";
import API, {graphqlOperation} from '@aws-amplify/api-graphql';
import { useState } from "react";
import { Button, ButtonGroup, Card, Col, Form, Row, Alert } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DayPicker, { DateUtils } from 'react-day-picker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import dayjs from 'dayjs';
import { listClients } from "../graphql/queries";
import { formatCurrency, parseTime } from "../services/formatters";
import { calculateWorkEarnings } from "../services/billable-work-service";
import { createWork } from "../graphql/mutations";
import OverlaySpinner from '../components/overlay-spinner';

function AdhocWorkForm(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [createOrUpdate] = useState(location.state && location.state.work ? 'update' : 'create');
    const [selectedClient, setSelectedClient] = useState('');
    const [timeWorked, setTimeWorked] = useState('');
    const [hourlyRate, setHourlyRate] = useState(0.0);
    const [notes, setNotes] = useState('');
    const [clients, setClients] = useState([]);
    const [dateRange, setDateRange] = useState({ from: null, to: null });
    const [moneyWorth, setMoneyWorth] = useState(0.0);
    const [isLoading, setIsLoading] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const [daysWorked, setDaysWorked] = useState(undefined);

    const searchClients = async (query) => {
        setIsLoading(true);

        try {
            const clientsData = await API.graphql(graphqlOperation(listClients));
            const clients = clientsData.data.listClients.items.filter( c => c.name.toLowerCase().includes(query.toLowerCase()));
            setClients(clients);
        } catch (err) {
            console.error('error fetching clients', err);
        }

        setIsLoading(false);
    };

    const updateMoneyWorth = (numDays = 1) => {
        let income = calculateWorkEarnings({
            timeWorked: parseTime(timeWorked),
            hourlyRate: hourlyRate * 100
        });
        setMoneyWorth(income * numDays);
        // const hoursWorked = parseTime(formState.timeWorked) / 60.0;
        // const hourlyRate = contract ? parseInt(contract.hourlyRate) : 0.0;

        // let moneyWork = hoursWorked * hourlyRate;
        // moneyWork = isNaN(moneyWork) ? 0.0 : moneyWork;
        // setMoneyWorth(moneyWork);
    };

    const createOrUpdateWork = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        setIsBusy(true);

        const errors = {};

        if (!selectedClient) {
            errors.contract = 'Please select a client';
        }

        if (!timeWorked) {
            errors.timeWorked = 'How many hours did you work?';
        }

        if (dateRange.from == null) {
            errors.dateWorked = 'When did you work?';
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setValidated(false);
            setIsBusy(false);
            return;
        }

        setValidated(true);

        try {
            // TODO For a date range, loop through and create work for wach day
            const workDate = dateRange.from;
            const work = {
                clientID: selectedClient.id,
                dateWorked: workDate.toLocaleDateString('en-CA'),
                hourlyRate: hourlyRate * 100,
                timeWorked: parseTime(timeWorked),
                fiscalYear: workDate.getFullYear(),
                notes: notes
            };

            if (createOrUpdate === 'create') {
                await API.graphql(graphqlOperation(createWork, { input: work }));
            }

            navigate('/home/work');
        } catch (error) {
            console.error('error adding work', error.errors);
            setIsBusy(false);
        }
    };

    const handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, dateRange);

        let numOfDays = 1;
        if (range.to) {
            numOfDays = dayjs(range.to).diff(dayjs(range.from), 'day') + 1;
        }

        updateMoneyWorth(numOfDays);
        setDaysWorked(numOfDays);
        setDateRange(range);
        formErrors.dateWorked = null;
    };

    return (
        <OverlaySpinner show={isBusy}>
            <>
                <Card.Title>Ad-hoc Work</Card.Title>
                <Form noValidate validated={validated} onSubmit={createOrUpdateWork}>
                    <Row className="mb-3">
                        <Col>
                            <Form.Group controlId="formClient">
                                <Form.Label>Client</Form.Label>
                                <AsyncTypeahead
                                    required
                                    id="client-typeahead"
                                    filterBy={() => true}
                                    isLoading={isLoading}
                                    labelKey="name"
                                    minLength={2}
                                    onSearch={searchClients}
                                    onChange={selected => setSelectedClient(selected[0])}
                                    options={clients}
                                    placeholder="start typing for client..."
                                    defaultInputValue={selectedClient? selectedClient.name: ''}
                                    isInvalid={!!formErrors.client}
                                />
                                <Form.Control.Feedback type="invalid">
                                    please select a registered client
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form.Group controlid="formTimeWorked">
                                <Form.Label>Time worked</Form.Label>
                                <Form.Control required type="text" placeholder="e.g. 7h 30m"
                                    onChange={event => {
                                        const value = event.target.value;
                                        setTimeWorked(value);
                                    }}
                                    onBlur={(event) => {
                                        updateMoneyWorth();
                                        if (event.target.value === '') {
                                            formErrors.timeWorked = 'how many hours did you work?';
                                        } else {
                                            formErrors.timeWorked = null;
                                        }
                                    }}
                                    value={timeWorked}
                                    isInvalid={!!formErrors.timeWorked} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlid="formHourlyRate">
                                <Form.Label>Rate per hour ($)</Form.Label>
                                <Form.Control required type="number" placeholder="Hourly rate"
                                    onChange={event => setHourlyRate(event.target.value)}
                                    onBlur={(event) => {
                                        updateMoneyWorth();
                                        if (event.target.value === '') {
                                            formErrors.timeWorked = 'how many hours did you work?';
                                        } else {
                                            formErrors.timeWorked = null;
                                        }
                                    }}
                                    value={hourlyRate}
                                    isInvalid={!!formErrors.hourlyRate} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form.Group controlId="formNotes">
                                <Form.Label>Notes</Form.Label>
                              <Form.Control as="textarea" rows={3} value={notes} onChange={event => setNotes(event.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <DayPicker
                                selectedDays={[dateRange.from, dateRange]}
                                modifiers={{ start: dateRange.from, end: dateRange.to }}
                                onDayClick={handleDayClick}
                            />
                        </Col>
                        <Col>
                            <Alert variant={formErrors.dateWorked ? "danger" : "info"} className="mt-3">
                                <Alert.Heading>Work Days</Alert.Heading>
                                <div className="text-center">
                                    {formErrors.dateWorked}
                                    <p>{dateRange.from && dateRange.from.toLocaleDateString()}</p>
                                    <p>{dateRange.to && ` to `}</p>
                                    <p>{dateRange.to && dateRange.to.toLocaleDateString()}</p>
                                    <hr />
                                    <p>Money Worth: {formatCurrency(moneyWorth)}</p>
                                    <p><small>{hourlyRate &&
                                        `${formatCurrency(hourlyRate*100)}/hour`} <FontAwesomeIcon icon={faTimes} size="xs" /> {timeWorked}
                                            { ' '} <FontAwesomeIcon icon={faTimes} size="xs" /> {daysWorked} days
                                    </small></p>
                                </div>
                            </Alert>
                        </Col>
                    </Row>
                    <ButtonGroup className="w-100">
                        <Button as={Link} to="/home/work" variant="secondary-outline" type="button">
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">Save</Button>
                    </ButtonGroup>
                </Form>
            </>
        </OverlaySpinner>
    );
}

export default withAuthenticator(AdhocWorkForm);
