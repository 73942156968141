import { useState } from 'react';
import { Auth } from "aws-amplify";
import { Form, Button, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import { useAppContext } from "../lib/app-context";

function SignUp(props) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

    const { userHasAuthenticated } = useAppContext();
    const navigateTo = useNavigate();

    const signUp = async (event) => {
        event.preventDefault()

        try {
            const { user } = await Auth.signUp({
                email,
                password, attributes: {
                    name: ''
                }
            })
            userHasAuthenticated(true);
            console.log('Signup success: ', user);
            navigateTo("/home");
        } catch (error) {
            console.log('Signup error: ', error);
        }
    };

    return (
        <div className="d-flex flex-row align-items-start justify-content-between" style={{ height: "100vh" }}>
            <div className="align-self-center mx-auto">
                <Container className="bg-white p-5 pt-3 pb-4 border rounded-3">
                    <div className="text-center">
                        <h2 className="pb-1">Get started with Billable Work</h2>
                        <h4>The smart way to manage your work</h4>
                    </div>
                    <Form onSubmit={signUp}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <div className="text-center">
                            <Button variant="primary" type="submit">
                                Sign up
                            </Button>
                        </div>
                    </Form>
                    <div className="mt-3 text-center">
                        <span className="text-muted">Already have an account?</span>&nbsp;
                        <Link to="/signup">Sign in</Link>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default SignUp;
