import { useState, useEffect, useContext } from 'react';
import { withAuthenticator } from "@aws-amplify/ui-react";
import API, {graphqlOperation} from '@aws-amplify/api-graphql';
import { Row, Col, Button, ButtonGroup, Form } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as mutations from '../graphql/mutations';

import UserContext from '../user-context';

const emptyForm = {
	firstName: '',
	lastName: '',
	companyName: '',
	companyEmail: null,
	companyPhone: ''
};

function ProfileForm(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const { userProfile, setUserProfile } = useContext(UserContext);
	const [formState, setFormState] = useState(emptyForm);
	const [currentUserEmail] = useState(location.state.currentUserEmail);
	const [formErrors] = useState({});
	// const [userProfile] = useState(location.state? location.state.profile: {});

	useEffect(() => {
		if (Object.keys(userProfile).length > 0) {
			const formState = {
				firstName: userProfile.firstName,
				lastName: userProfile.lastName
			};
			if (userProfile.company) {
				formState.companyName = userProfile.company.name || '';
				formState.companyPhone = userProfile.company.phone || '';
				formState.companyEmail = userProfile.company.email || null;
			}
			setFormState(formState);
		}
		return () => {};
	}, [userProfile]);

	const upsertProfileCompany = async () => {
		let profileCompanyId = '';

		const profileCompany = {
			name: formState.companyName,
			phone: formState.companyPhone || null,
			email: formState.companyEmail || null
		};
		if (userProfile.company.id) {
			await API.graphql(graphqlOperation(mutations.updateProfileCompany, {input:
				{
					...profileCompany,
					id: userProfile.company.id
				}
			}));
			profileCompanyId = userProfile.company.id;
		} else {
			const createProfileCompanyResult = await API.graphql(graphqlOperation(mutations.createProfileCompany, {input: profileCompany}));
			profileCompanyId = createProfileCompanyResult.data.createProfileCompany.id;
		}

		setUserProfile({...userProfile, company: profileCompany});

		return profileCompanyId;
	};

	const updateProfile = async () => {
		try{
			const profileCompanyId = await upsertProfileCompany();

			const profile = {
				firstName: formState.firstName,
				lastName: formState.lastName
			};

			if (userProfile.id) {
				const updateProfile = {
					...profile,
					id: userProfile.id
				};
				await API.graphql(graphqlOperation(mutations.updateProfile, {input: updateProfile}));
			} else {
				await API.graphql(graphqlOperation(mutations.createProfile, {input:
					{
						...profile,
						email: currentUserEmail,
						companyID: profileCompanyId
					}
				}));
			}

			navigate('/home/profile');
			// const profileCompany = {
			// 	profileID: null,
			// 	name: formState.companyName,
			// 	phone: formState.companyPhone,
			// 	email: formState.companyEmail
			// }
			// const updateProfileCompanyResult = await API.graphql(graphqlOperation(mutations.updateProfileCompany, {input: profileCompany}));
			// console.log(updateProfileCompanyResult);
		} catch (error) {
			console.error(error);
		}
	};

	function setInput(key, value) {
        setFormState({ ...formState, [key]: value });
    }	

	return (
		<>
			<h1 className="mb-3">Edit Profile</h1>
			<Row>
				<Col className="d-flex justify-content-end">
					<ButtonGroup>
						<Button variant="secondary" as={Link} to="/home/profile">
							Cancel
						</Button>
						<Button variant="primary" onClick={updateProfile}>
							Save
						</Button>
					</ButtonGroup>
				</Col>
			</Row>
			<section>
				<div className="d-flex d-flex-row border-bottom text-muted">
					<h5 className="mb-0">Personal</h5>
					<small className="ms-2 align-self-end"> - {currentUserEmail}</small>
				</div>
				<Row className="ms-2 pt-4">
					<Col md="6">
                        <Form.Group className="mb-3 row" controlId="formProfileFirstName">
                            <Form.Label column="5">First Name</Form.Label>
                            <Col>
	                            <Form.Control required type="text" placeholder="First Name"
	                                onChange={event => setInput('firstName', event.target.value)}
	                                value={formState.firstName}
	                                isInvalid={!!formErrors.firstName} />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-3 row" controlId="formProfileLastName">
                            <Form.Label column="5">Last Name</Form.Label>
                            <Col>
	                            <Form.Control required type="text" placeholder="Last Name"
	                                onChange={event => setInput('lastName', event.target.value)}
	                                value={formState.lastName}
	                                isInvalid={!!formErrors.lastName} />
                            </Col>
                        </Form.Group>
                    </Col>
				</Row>
			</section>
			<section className="mt-5">
				<h5 className="border-bottom text-muted">
					Company / Organization
				</h5>
				<Row className="ms-2 pt-4">
					<Col md="6">
                        <Form.Group className="mb-3 row" controlId="formProfileCompanyName">
                            <Form.Label column="5">Company Name</Form.Label>
                            <Col>
	                            <Form.Control required type="text" placeholder="Company Name"
	                                onChange={event => setInput('companyName', event.target.value)}
	                                value={formState.companyName}
	                                isInvalid={!!formErrors.companyName} />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-3 row" controlId="formProfileCompanyEmail">
                            <Form.Label column="5">Email</Form.Label>
                            <Col>
	                            <Form.Control required type="email" placeholder="Company Email"
	                                onChange={event => setInput('companyEmail', event.target.value)}
	                                value={formState.companyEmail}
	                                isInvalid={!!formErrors.companyEmail} />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-3 row" controlId="formProfileCompanyPhone">
                            <Form.Label column="5">Phone</Form.Label>
                            <Col>
	                            <Form.Control required type="phone" placeholder="Company Phone"
	                                onChange={event => setInput('companyPhone', event.target.value)}
	                                value={formState.companyPhone}
	                                isInvalid={!!formErrors.companyPhone} />
                            </Col>
                        </Form.Group>
					</Col>
				</Row>
			</section>
		</>
	);
}

export default withAuthenticator(ProfileForm);
