import { withAuthenticator } from "@aws-amplify/ui-react";
import API, { graphqlOperation } from '@aws-amplify/api-graphql';
import { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as mutations from "../graphql/mutations";
import { listContracts } from "../graphql/queries";
import ContractCard from "../components/contract-card";

function Contracts(props) {
    const [contracts, setContracts] = useState([]);

    function fetchContracts() {
        const contractsPromise = API.graphql(
            graphqlOperation(listContracts)
        );

        return contractsPromise;
    }

    const deleteContractHandler = async (contractId) => {
        const index = contracts.findIndex((c) => c.id === contractId);
        if (index > -1) {
            let dup = contracts;
            dup.splice(index, 1);
            setContracts([...dup]);
        }
    };

    const changeContractStatus = async (contract, status) => {
        await API.graphql(graphqlOperation(mutations.updateContract, {
            input: {
                id: contract.id,
                status: status
            }
        }));

        const dups = contracts;
        const index = dups.findIndex((c) => c.id === contract.id);
        if (index > -1) {
            const c = dups[index];
            c.status = status;
            dups[index] = c;
            setContracts([...dups]);
        }
    };

    //    const statusBadgeColor = (contract) => {
    //        if (contract.status === 'Expired') {
    //            return 'danger';
    //        }
    //
    //        return 'info';
    //    };

    useEffect(() => {
        let contractsPromise = null;

        (async () => {
            contractsPromise = fetchContracts();
            contractsPromise.then(contractsResult => {
                const contracts = contractsResult.data.listContracts.items.filter(c => !c.changeType);
                setContracts(contracts);
            });
        })();

        return () => {
            API.cancel(contractsPromise);
        };
    }, []);

    return (
        <>
            <span className="h1 title text-primary">Contracts</span>
            <Row>
                <Col className="d-flex justify-content-end">
                    <Button variant="primary" as={Link} to="/home/contract/new">
                        New Contract
                    </Button>
                </Col>
            </Row>
            <Row className="g-4 mt-4">
                {contracts.length === 0 && <div>No contracts found</div>}
                <h1 className="separator text-success">Active</h1>
                {contracts.filter(c => c.status === 'Approve').map((contract, idx) => {
                    return (
                        <Col key={contract.id}>
                            <ContractCard contract={contract} idx={idx} onStatusChange={(newStatus) => changeContractStatus(contract, newStatus)} onDelete={() => deleteContractHandler(contract.id)} />
                        </Col>
                    )
                })}
                <h2 className="separator text-secondary">Inactive</h2>
                {contracts.filter(c => c.status !== 'Approve').map((contract, idx) => {
                    return (
                        <Col key={contract.id}>
                            <ContractCard contract={contract} idx={idx} onStatusChange={(newStatus) => changeContractStatus(contract, newStatus)} onDelete={() => deleteContractHandler(contract.id)} />
                        </Col>
                    );
                })}
            </Row >
        </>
    );
}

export default withAuthenticator(Contracts);

