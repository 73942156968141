import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltUp, faArrowAltDown } from "@fortawesome/pro-light-svg-icons";
import { formatPercent } from "../services/formatters";

export function HourlyRateDelta(props){
    const extension = props.extension;
    let arrow = faArrowAltUp;
    let arrowColor = 'text-success';

    if (extension.hourlyRateDelta < 0) {
        arrow = faArrowAltDown;
        arrowColor = 'text-danger';
    }

    return (
        <span>
            <FontAwesomeIcon icon={arrow} className={`me-1 ${arrowColor}`} />
            {formatPercent(extension.hourlyRateDelta)}
        </span>
    )
}

