import { useState } from 'react';
import { faBriefcase, faFileContract, faFileInvoiceDollar, faUsers, faTachometer } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import './actionbar.css';

function ActionBar(props) {
    const [active, setActive] = useState('dashboard');
    const navClassNames = ({
        'd-flex': true,
        'flex-row': props.horizontal,
        'flex-column': props.vertical,
        'justify-content-center': true
    });

    return (
        <Nav
            justify
            variant="pills"
            className={navClassNames}
            activeKey={active}
            onSelect={(selectedKey) => setActive(selectedKey)}
        >
            <Nav.Item className="pe-2">
                <Nav.Link as={Link} eventKey="dashboard" to="/home" className="actionbar">
                    <FontAwesomeIcon icon={faTachometer} size="lg" fixedWidth />
                    <div>Dashboard</div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className="pe-2">
                <Nav.Link as={Link} eventKey="work" to="/home/work" className="actionbar">
                    <FontAwesomeIcon icon={faBriefcase} size="lg" fixedWidth />
                    <div>Work</div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className="pe-2">
                <Nav.Link as={Link} eventKey="clients" to="/home/clients" className="actionbar">
                    <FontAwesomeIcon icon={faUsers} size="lg" fixedWidth />
                    <div>Clients</div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className="pe-2">
                <Nav.Link as={Link} eventKey="contracts" to="/home/contracts" className="actionbar">
                    <FontAwesomeIcon icon={faFileContract} size="lg" fixedWidth />
                    <div>Contracts</div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} eventKey="invoices" to="/home/invoices" className="actionbar">
                    <FontAwesomeIcon icon={faFileInvoiceDollar} size="lg" fixedWidth />
                    <div>Invoices</div>
                </Nav.Link>
            </Nav.Item>
        </Nav>
    );
}

            // <Button routerlink="time-off" routerlinkactive="active" title="Time off" variant="light"
            //     className="p-3" tabIndex="5">
            //     <FontAwesomeIcon icon={faUmbrellaBeach} size="lg" fixedWidth />
            // </Button>
            // <Button routerlink="reports" routerlinkactive="active" title="Reports" variant="light"
            //     className="p-3" tabIndex="6">
            //     <FontAwesomeIcon icon={faChartBar} size="lg" fixedWidth />
            // </Button>


export default ActionBar;
