export function formatCurrency(cents, currency) {
    const money = isNaN(cents)? 0: cents;
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(money/100);
}

export function formatInvoiceNumber(invoiceNumber) {
    return invoiceNumber? invoiceNumber.toString().padStart(7, 0): '';
}

export function formatPhone(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return '';
}

export function formatTime(minutes) {
    const hours = minutes / 60;

    return `${Math.trunc(hours)}h ${Math.trunc(minutes % 60)}m`;
}

export function formatDate(date) {
    return date.toLocaleDateString('en-US',
        {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
}

export function parseTime(time) {
    if (time === null || time === undefined) {
        return 0;
    }

    if (!isNaN(time)) {
        return time * 60;
    }

    let minutes = 0;

    const matches = time.match(/((\d+)([mh]))/g);
    if (matches !== null && matches !== undefined) {
        matches.forEach(m => {
            const unitOfTime = m.charAt(m.length - 1);
            if (unitOfTime === 'm') {
                minutes += parseInt(m, 10);
            } else if (unitOfTime === 'h') {
                minutes += parseInt(m, 10) * 60;
            }
        });
    }

    return minutes;
}

export function formatPercent(ratio) {
    const percent = (ratio * 100).toFixed(2);
    return `${percent}%`;
}

