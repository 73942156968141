import { Button, Card, Col, ListGroup, Row, ButtonGroup } from "react-bootstrap";
import { withAuthenticator } from "@aws-amplify/ui-react";
import API, {graphqlOperation} from '@aws-amplify/api-graphql';
import Auth from '@aws-amplify/auth';
import { listClients, listWorkSummaries } from '../graphql/queries';
import { deleteClient } from '../graphql/mutations';
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/pro-light-svg-icons";
import { formatPhone, formatCurrency, formatDate } from '../services/formatters';

function Clients(props) {
    const [clients, setClients] = useState([]);
    const [incomeByClient, setIncomeByClient] = useState({});

    const deleteClientHandler = async (clientId) => {
        await API.graphql(graphqlOperation(deleteClient, { input: { id: clientId } }));
        setClients(clients.filter(c => c.id !== clientId));
    };

    useEffect(() => {
        const currentUserPromise = Auth.currentAuthenticatedUser();
        const listClientsPromise = API.graphql(graphqlOperation(listClients));

        (async () => {
            try {
                const currentUserResponse = await currentUserPromise;
                const currentAuthenticatedUserId = currentUserResponse.username;
                const listClientsResponse = await listClientsPromise;
                const clients = listClientsResponse.data.listClients.items;

                setClients(clients);

                const listWorkSummariesPromises = API.graphql(
                    graphqlOperation(listWorkSummaries, {
                        id: `${currentAuthenticatedUserId}:0000`,
                    })
                );
                const workSummaryResponse = await listWorkSummariesPromises;
                const workSummary = workSummaryResponse.data.listWorkSummaries.items;
                if (workSummary.length > 0) {
                    setIncomeByClient(JSON.parse(workSummary[0].incomeByClient));
                }
            } catch (err) {
                if (!API.isCancel(err)) {
                    console.error(err);
                }
            }
        })();

        return () => {
            API.cancel(listClientsPromise, '');
        };
    }, []);

    return (
        <>
            <span className="h1 title text-primary">Clients</span>
            <Row>
                <Col className="d-flex justify-content-end">
                    <Button variant="primary" as={Link} to="/home/client/new">New Client</Button>
                </Col>
            </Row>
            <Row xs={1} md={3} className="mt-2">
                {clients.map((client) => {
                    return (
                        <Col key={client.id}>
                            <Card border="info m-3">
                                <Card.Header>
                                    <h3>{client.name}</h3>
                                    <dl>
                                        <dt>All Earnings</dt>
                                        <dd>{formatCurrency(incomeByClient[client.id])}</dd>
                                    </dl>
                                </Card.Header>
                                <ListGroup>
                                    <ListGroup.Item>
                                        <div className="fs-6">Email:</div>
                                        <div className="fs-6 ms-4">{client.email || 'Not Available'}</div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div className="fs-6">Phone:</div>
                                        <div className="fs-6 ms-4">{formatPhone(client.phone) || 'Not Available'}</div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div className="fs-6">Created On:</div>
                                        <div className="fs-6 ms-4">{formatDate(new Date(client.createdAt))}</div>
                                    </ListGroup.Item>
                                </ListGroup>
                                <Card.Footer className="d-flex justify-content-end">
                                    <ButtonGroup>
                                        <Button as={Link}
                                            to='/home/client/update'
                                            state={{ client: client }}
                                            variant="outline-primary" type="button">
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                        <Button onClick={() => deleteClientHandler(client.id)} variant="outline-danger" type="button">
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </ButtonGroup>
                                </Card.Footer>
                            </Card>
                        </Col>
                    );
                })}
            </Row>
        </>
    );
}

export default withAuthenticator(Clients);
