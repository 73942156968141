import { Authenticator, Heading, useTheme, View, Image, useAuthenticator } from "@aws-amplify/ui-react";
import React from "react";
import { Navigate } from "react-router-dom";

function SignIn(props) {
    const { user } = useAuthenticator((context) => [context.user]);

    const components = {
        Header() {
            const { tokens } = useTheme();

            return (
                <View textAlign="center" padding={tokens.space.large} >
                    <Image
                        alt="BillableWork logo"
                        src={`${process.env.PUBLIC_URL}/images/logo@167.png`}
                    />
                </View >
            );
        },

        SignIn: {
            Header() {
                const { tokens } = useTheme();

                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        Sign in
                    </Heading>
                );
            }
        }
    };
    return (
        <React.Fragment>
            { !user && <Authenticator components={components} /> }
            { user && <Navigate to="/home" /> }
        </React.Fragment>
    );
}

export default SignIn;
