import { Container, ListGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faStopwatch,
    faUserHeadset,
    faFileInvoiceDollar,
    faFileSignature,
    faFileSearch,
    faSearchDollar,
    faAlarmClock,
    faPencil,
    faFileSpreadsheet,
    faThumbsUp,
    faHandshake,
    faCheck
} from "@fortawesome/pro-light-svg-icons";
import Footer from '../components/footer';

function Landing(props) {
    return (
        <Container fluid>
            <div className="row section bg-primary text-white ps-5 pt-5">
                <div className="col-7" style={{ padding: "3rem 0 0 2rem" }}>
                    <span className="h2">
                        One-stop productivity tool to securely manage your
                        Billable Work
                    </span>
                    <div className="row text-white text-center pt-5">
                        <div className="col-3">
                            <FontAwesomeIcon icon={faStopwatch} size="2x" />
                            <p>Intelligent Time Tracking</p>
                        </div>
                        <div className="col-3">
                            <FontAwesomeIcon icon={faUserHeadset} size="2x" />
                            <p>Contract and Client Handling</p>
                        </div>
                        <div className="col-3">
                            <FontAwesomeIcon icon={faFileInvoiceDollar} size="2x" />
                            <p>Seamless Billing and Invoicing</p>
                        </div>
                        <div className="col-3">
                            <FontAwesomeIcon icon={faSearchDollar} size="2x" />
                            <p>Opportunity Cost Monitoring</p>
                        </div>
                    </div>
                </div>
                <div
                    className="col-5 pr-0"
                    style={{ padding: "3rem 2rem 0 0" }}
                >
                    <img
                        className="float-right"
                        src={`${process.env.PUBLIC_URL}/images/laptop@2x.png`}
                        alt=""
                    />
                </div>
            </div>
            <div className="row section bg-light ps-5 pt-5 py-5">
                <div className="col-6">
                    <h5>
                        Are You Struggling to Optimize Your Work, Productivity
                        and Contacts?
                    </h5>
                    <div className="text-muted">
                        <p>
                            Using multiple different software like emails,
                            spreadsheets, stand-alone contract management tools
                            and CRMs for client management can be tiresome,
                            wasteful and inefficient. Further, existing software
                            often don’t meet your complex project needs and
                            compromise on security standards, causing you to use
                            paper back-ups for important documents like
                            invoices, agreements and receipts.
                        </p>
                        <p>
                            Billable Work is a smart, secure, all-in-one
                            solution to manage and track all your billable work,
                            clients, contracts, invoices and opportunity costs
                            in a single location.
                        </p>
                    </div>
                </div>
                <div className="col-6">
                    <img
                        className="text-center"
                        src={`${process.env.PUBLIC_URL}/images/Illustration@2x.png`}
                        alt=""
                    />
                </div>
            </div>
            <div className="row section bg-white ps-5 pt-5">
                <h2 className="col-12 text-center mb-5">Our Features</h2>
                <div className="col-8">
                    <ul className="list">
                        <li>
                            Billable Work uses the latest in Web technology for the ultimate user experience.
                        </li>
                        <li>
                            Our Mobile App is coming soon! Want to help?
                            Sign up for the
                            <a href="https://testflight.apple.com/join/Xq1ph85t">
                                {" "}
                                beta
                            </a>
                            . We could use the help.
                        </li>
                        <li>
                            Billable Work comes loaded with the following
                            features.
                        </li>
                    </ul>
                </div>
                <hr />
                <div className="col-4 pr-0">
                    <div
                        className="mobile-banner"
                        style={{
                            backgroundImage:
                                "url('assets/images/ribbon@2x.png')",
                        }}
                    >
                        <h5 className="m-3">
                            <a
                                href="https://testflight.apple.com/join/Xq1ph85t"
                                className="text-white"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="fal fa-mobile"></i> GET EARLY
                                ACCESS
                            </a>
                        </h5>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <div className="row">
                        <div className="col-2 text-center">
                            <FontAwesomeIcon icon={faAlarmClock} size="2x" />
                            <div>Smart Tracker</div>
                        </div>
                        <div className="col-2 text-center">
                            <FontAwesomeIcon icon={faFileInvoiceDollar} size="2x" />
                            <div>Invoice Creator</div>
                        </div>
                        <div className="col-2 text-center">
                            <FontAwesomeIcon icon={faUserHeadset} size="2x" />
                            <div>Client Organizer</div>
                        </div>
                        <div className="col-2 text-center">
                            <FontAwesomeIcon icon={faFileSignature} size="2x" />
                            <div>Contract Manager</div>
                        </div>
                        <div className="col-2 text-center">
                            <FontAwesomeIcon icon={faFileSearch} size="2x" />
                            <div>Expense Monitor</div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-5 ps-5">
                    <div className="row">
                        <div className="col-7">
                            <h5>
                                Spend less time tracking and more time earning
                            </h5>
                            <div className="row">
                                <div className="col-6 mb-3">
                                    <div className="card h-100 rounded">
                                        <div className="card-body d-flex">
                                            <FontAwesomeIcon icon={faPencil} size="2x" className="pe-3 text-primary" />
                                            Log the billable hours for different clients
                                            & projects
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="card h-100 rounded bg-light">
                                        <div className="card-body d-flex">
                                            <FontAwesomeIcon icon={faHandshake} size="2x" className="pe-3 text-primary" />
                                            Impress your clients with extensive
                                            reporting
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="card h-100 rounded bg-light">
                                        <div className="card-body d-flex">
                                            <FontAwesomeIcon icon={faFileSpreadsheet} size="2x" className="pe-3 text-primary" />
                                            Use timesheets & schedulers for better
                                            negotiation
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="card h-100 rounded">
                                        <div className="card-body d-flex">
                                            <FontAwesomeIcon icon={faThumbsUp} size="2x" className="pe-3 text-primary" />
                                            Minimize delays, back-logs and incomplete
                                            projects
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-5">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/offering screenshot@2x.png`}
                                alt="Work timer screenshot"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row section bg-primary ps-5">
                <h2 className="col-12 text-white text-center pt-5 mb-5">Why Us</h2>
                <div className="col-5">
                    <img
                        src={`${process.env.PUBLIC_URL}/images/why us screenshot@2x.png`}
                        alt="Why Us screenshot"
                    />
                </div>
                <div className="col-7 ps-3">
                    <ListGroup variant="flush">
                        <ListGroup.Item variant="primary">
                            <h5>Security and User Privacy</h5>
                            <p>
                                Our end-to-end encryption ensures that no one
                                can access sensitive user-owned data other than
                                the user themselves (not even system
                                administrators). Data is encrypted while in
                                transit and rest using the latest security
                                technologies.
                            </p>
                        </ListGroup.Item>
                        <ListGroup.Item variant="primary">
                            <h5>Import and Export Your Data <small className="text-muted">(coming soon)</small></h5>
                        </ListGroup.Item>
                        <ListGroup.Item variant="primary">
                            <h5>Dedicated and Responsive Support</h5>
                        </ListGroup.Item>
                        <ListGroup.Item variant="primary">
                            <h5>Extensive &amp; Comprehensive Reporting</h5>
                        </ListGroup.Item>
                    </ListGroup>
                </div>
            </div>
            <div className="section bg-white text-inverse py-5 d-flex flex-column justify-content-center align-items-center">
                <div className="mb-5">
                    <h1 className="text-center pt-5">Pricing</h1>
                    <ul className="list-inline">
                        <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" /> No credit card required</li>
                    </ul>
                </div>
                <div className="card border-light rounded shadow" style={{ width: "25rem" }}>
                    <div className="card-header d-flex justify-content-between">
                        <h2>FREE</h2>
                        <div className="alert bg-success text-white"><h4>$0</h4></div>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">While in <i>beta</i> and for the first 6 months after that.</h5>
                        <p className="card-text">
                            You get all features for free (while in beta). And, as a thank you, you get <strong>6 months </strong>
                            free after the service is out of beta.
                        </p>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item"><FontAwesomeIcon icon={faCheck} className="text-success me-2" /> Manage your billable work</li>
                            <li className="list-group-item"><FontAwesomeIcon icon={faCheck} className="text-success me-2" /> Manage clients</li>
                            <li className="list-group-item"><FontAwesomeIcon icon={faCheck} className="text-success me-2" /> Create invoices</li>
                            <li className="list-group-item"><FontAwesomeIcon icon={faCheck} className="text-success me-2" /> Year-to-date view of billable work</li>
                        </ul>
                    </div>
                    <div className="card-footer d-grid text-center">
                        <Button variant="primary" size="lg" href="#/signin">
                            Get started
                        </Button>
                    </div>
                </div>
            </div>
            <Footer />
            {/*<div className="row pt-5 pb-5 bg-light text-center">
                <div className="col-12 text-muted">
                    <h5>
                        Billable Work is <i className="text-primary">free</i> to
                        use.
                    </h5>
                    <p>
                        Sign up today for free and access all features with no
                        expiration.
                    </p>
                </div>
                <div className="col-12 mt-3">
                    <button className="btn btn-lg btn-rounded btn-primary">
                        Sign up now
                    </button>
                </div>
            </div>/*}
            {/*}<div className="row pt-5 bg-dark text-white text-center border-top">
                <h2 className="col-12 text-white text-center mb-5">
                    Subscribe to our newsletter
                </h2>
                <div className="col-12">
                    <p>
                        Subscribe to our newsletter to receive news, updates and
                        other useful information from time to time.
                    </p>
                    <form>
                        <div className="row">
                            <div className="form-group col-md-3 m-auto">
                                <input
                                    type="email"
                                    name="subscribeEmail"
                                    className="form-control mb-2"
                                    placeholder="Your Email Address"
                                    required
                                />
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled="subscribeForm.invalid"
                                >
                                    Subscribe
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="form-text text-success"></p>
                                <p className="form-text text-danger"></p>
                            </div>
                        </div>
                    </form>
                    <p className="text-muted mt-3">
                        <small>
                            We respect your privacy and will not share your
                            email with anyone. We just are that nice.
                        </small>
                    </p>
                    </div>
                    </div>*/}
        </Container >
    );
}

export default Landing;

