import { useState, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-light-svg-icons";
import { Button, ButtonGroup, Card, Toast } from 'react-bootstrap';
import dayjs from 'dayjs';
import useOutsideClick from '../hooks/useOutsideClick';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function MonthYearSelect(props) {
    const [year, setYear] = useState(dayjs().year());
    const [selected, setSelected] = useState(dayjs().startOf('month'));
    const [showSelect, setShowSelect] = useState(false);

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => setShowSelect(false));

    const updateSelected = (month) => {
        const monthYear = dayjs(`${month} ${year}`, 'MMMM YYYY');
        setSelected(monthYear);
        setShowSelect(false);

        props.onChange && props.onChange(monthYear.toDate());
    };

    const monthClassName = (month) => {
        let classes = '';
        if (selected.format('MMMM') !== month) {
            classes += 'border-0';
        }

        return classes;
    };

    const Months = () => {
        return <>
            <ButtonGroup className="w-100">
            <Button variant="outline-primary" className={monthClassName(months[0])} style={{width: '33%'}} onClick={() => updateSelected(months[0])}>{months[0]}</Button>
        <Button variant="outline-primary" className={monthClassName(months[1])} style={{width: '33%'}} onClick={() => updateSelected(months[1])}>{months[1]}</Button>
        <Button variant="outline-primary" className={monthClassName(months[2])} style={{width: '33%'}} onClick={() => updateSelected(months[2])}>{months[2]}</Button>
            </ButtonGroup>
            <ButtonGroup className="w-100">
        <Button variant="outline-primary" className={monthClassName(months[3])} style={{width: '33%'}} onClick={() => updateSelected(months[3])}>{months[3]}</Button>
        <Button variant="outline-primary" className={monthClassName(months[4])} style={{width: '33%'}} onClick={() => updateSelected(months[4])}>{months[4]}</Button>
        <Button variant="outline-primary" className={monthClassName(months[5])} style={{width: '33%'}} onClick={() => updateSelected(months[5])}>{months[5]}</Button>
            </ButtonGroup>
            <ButtonGroup className="w-100">
        <Button variant="outline-primary" className={monthClassName(months[6])} style={{width: '33%'}} onClick={() => updateSelected(months[6])}>{months[6]}</Button>
        <Button variant="outline-primary" className={monthClassName(months[7])} style={{width: '33%'}} onClick={() => updateSelected(months[7])}>{months[7]}</Button>
        <Button variant="outline-primary" className={monthClassName(months[8])} style={{width: '33%'}} onClick={() => updateSelected(months[8])}>{months[8]}</Button>
            </ButtonGroup>
            <ButtonGroup className="w-100">
        <Button variant="outline-primary" className={monthClassName(months[9])} style={{width: '33%'}} onClick={() => updateSelected(months[9])}>{months[9]}</Button>
        <Button variant="outline-primary" className={monthClassName(months[10])} style={{width: '33%'}} onClick={() => updateSelected(months[10])}>{months[10]}</Button>
        <Button variant="outline-primary" className={monthClassName(months[11])} style={{width: '33%'}} onClick={() => updateSelected(months[11])}>{months[11]}</Button>
            </ButtonGroup>
        </>;
    };

    const previousMonth = () => {
        const monthYear = selected.add(-1, 'month');
        setSelected(monthYear);
        props.onChange && props.onChange(monthYear.toDate());
        setShowSelect(false);
    };

    const nextMonth = () => {
        const monthYear = selected.add(1, 'month');
        setSelected(monthYear);
        props.onChange && props.onChange(monthYear.toDate());
        setShowSelect(false);
    };

    const nextYear = () => {
        setYear(year + 1);
    };

    const previousYear = () => {
        setYear(year - 1);
    };

    return (
        <div ref={wrapperRef} className="d-flex d-flex-row align-items-baseline">
            <Button variant="outline-primary" className="border-0" onClick={previousMonth}>
                <FontAwesomeIcon icon={faAngleLeft} />
            </Button>
            <div className="d-flex flex-column align-items-center">
                <Toast className="b-0" style={{ 'width': 'auto' }} onClick={() => setShowSelect(!showSelect)}>
                    <Toast.Body>
                        {Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long' }).format(selected.toDate())}
                    </Toast.Body>
                </Toast>
                {showSelect && <Card style={{position: 'absolute', top: '185px', width: '360px', zIndex: '1000'}}>
                    <Card.Body>
                    <Card.Title className="d-flex justify-content-center">
                        <Button variant="outline-primary border-0" onClick={previousYear}>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </Button>
                        <Toast className="b-0" style={{ 'width': 'auto' }}>
                        <Toast.Body>
                            {year}
                        </Toast.Body>
                        </Toast>
                        <Button variant="outline-primary border-0" onClick={nextYear}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </Button>
                    </Card.Title>
                    <Months/>
                    </Card.Body>
                </Card>}
            </div>
            <Button variant="outline-primary" className="border-0" onClick={nextMonth} disabled={dayjs(selected).isSame(dayjs(), 'month')}>
                <FontAwesomeIcon icon={faAngleRight} />
            </Button>
        </div>
    );
}

export default MonthYearSelect;
